import useAxios from "axios-hooks";
import { ArticleSchema, BioSchema, DataSchema, ExhibitionSchema, Settings, Work } from "../../../schemas";
import { useEffect, useMemo } from "react";
import { useGlobal } from "../../../state/global/useGlobal";
import { AxiosError } from "axios";

export const makeApiUrl = (url: string) => {
  return `/api/v1${url}`;
};

interface IUseAllDataLaoder {
  data?: DataSchema;
  loaded?: boolean;
  error?: AxiosError<boolean, boolean> | null;
}

export const useAllDataLoader = (): IUseAllDataLaoder => {
  const { setIsDataLoaded } = useGlobal();
  const [{ data: works, loading: loadingW, error: errorW }] = useAxios<Work[], boolean, boolean>({
    url: makeApiUrl("/works"),
    method: "GET",
  });
  const [{ data: articles, loading: loadingA, error: errorA }] = useAxios<ArticleSchema[], boolean, boolean>({
    url: makeApiUrl("/articles"),
    method: "GET",
  });
  const [{ data: exhibitions, loading: loadingE, error: errorE }] = useAxios<ExhibitionSchema[], boolean, boolean>({
    url: makeApiUrl("/exibitions"),
    method: "GET",
  });
  const [{ data: bio, loading: loadingB, error: errorB }] = useAxios<BioSchema, boolean, boolean>({
    url: makeApiUrl("/bio"),
    method: "GET",
  });
  const [{ data: settings, loading: loadingS, error: errorS }] = useAxios<Settings[], boolean, boolean>({
    url: makeApiUrl("/settings"),
    method: "GET",
  });

  const dataLoaded = useMemo(() => {
    return !loadingW || !loadingA || loadingE || !loadingB || !loadingS;
  }, [loadingW, loadingA, loadingE, loadingB, loadingS]);

  useEffect(() => {
    if (!dataLoaded || !works || !articles || !exhibitions || !settings || !bio) {
      return;
    }
    setIsDataLoaded({ data: { works, articles, exhibitions, settings, bio } });
  }, [dataLoaded, works, articles, exhibitions, bio, settings]);

  return {
    error: errorA || errorW || errorE || errorB || errorS,
  };
};

import AnimatedPage from "../../components/templates/AnimatedPage";
import { Paths } from "../../navigation/types";
import Footer from "../../components/molecules/footer/Footer";
import React from "react";
import { useHomePage } from "./useHomePage";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import CustomTypography from "../../components/atoms/customTypography/customTypography";
import { Grid } from "@mui/material";
import ArticleCard from "../../components/atoms/articleCard/ArticleCard";
import ImageHeader from "../../components/atoms/image/Image";
import Logo from "../../components/atoms/Logo/Logo";

const HomePage = () => {
  const { contentRef, home_image, loading, overlay, sortedArticles, show, styles, t, onLoadImage } = useHomePage();

  return (
    <AnimatedPage key={Paths.Home} style={styles.container}>
      <div id="home-container">
        {overlay && <div style={styles.modalBcg} />}
        <div style={styles.containerHeader}>
          <Logo show={show} />
          {loading && <LoadingSkeleton width="100%" height="100%" />}
          <ImageHeader src={home_image} y={-0.1} alt={t("label.altHomeImage")} onLoadImage={onLoadImage} />
        </div>
        <div ref={contentRef} style={styles.contentContainer}>
          <CustomTypography style={styles.recentText}>{t("label.recent")}</CustomTypography>
          <Grid container spacing={6} style={styles.gridContainer} rowSpacing={{ xs: 4 }}>
            {sortedArticles.map((article, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <ArticleCard article={article} style={{ width: "100%" }} />
                </Grid>
              );
            })}
          </Grid>
        </div>
        <Footer />
      </div>
    </AnimatedPage>
  );
};

export default HomePage;

import { Card } from "@mui/material";
import React, { CSSProperties, useMemo } from "react";
import CustomTypography from "../customTypography/customTypography";
import { LoadingSkeleton } from "../loadingSkeleton/LoadingSkeleton";
import "./style.css";
import { useWorkCard } from "./useWorkCard";
import { Work } from "../../../schemas";
import FocusedImage from "../focusedImage/FocusedImage";

export interface IWorkCard {
  work: Work;
  style?: CSSProperties;
}

const WorkCard = (props: IWorkCard) => {
  const { work, loading, style, styles, title, onWorkClick, onLoadImage } = useWorkCard(props);

  const Image = useMemo(() => {
    return (
      <div className={"img"}>
        <FocusedImage
          src={work.image_thumbnail}
          alt={title}
          style={{ display: loading ? "none" : "block" }}
          x={work.x_thumbnail_position ? work.x_thumbnail_position : undefined}
          y={work.y_thumbnail_position ? work.y_thumbnail_position : undefined}
          onLoadImage={onLoadImage}
        />
      </div>
    );
  }, [loading]);

  return (
    <Card style={{ ...styles.card, ...style }}>
      <div style={styles.imgContainer} onClick={onWorkClick}>
        {loading && <LoadingSkeleton width="100%" height="100%" />}
        {Image}
      </div>
      <div style={styles.cardContent}>
        <CustomTypography gutterBottom variant="body1">
          {title}
        </CustomTypography>
      </div>
    </Card>
  );
};

export default WorkCard;

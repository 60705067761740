import {
  SET_LANDING_PAGE_IMAGES,
  SET_FINISHED_SCROLLING,
  SET_IS_DATA_LOADED,
  SET_LANGUAGE,
  SET_MODAL,
  SET_THEME,
  SET_HIDE_LANDING_PAGE,
} from "./global.constants";
import { Paths } from "../../navigation/types";
import { DataSchema } from "../../schemas";

export enum Languages {
  it = "it",
  en = "en",
}

export type Language = Languages.it | Languages.en;
export type Theme = "light" | "dark";
export type Modal = {
  open: boolean;
  path?: Paths.Works | Paths.Diary;
};

//**STATE**//
export type GlobalState = {
  data: DataSchema;
  isDataLoaded: boolean;
  finishedScrolling: boolean;
  hideLandingPage: boolean;
  landingPageImages: HTMLImageElement[];
  language?: Language;
  modal: Modal;
  server_url?: string;
  version?: string;
  theme: Theme;
};

//**ACTIONS**//
export interface SetLandingPageImages {
  landingPageImages: HTMLImageElement[];
}

export interface SetLanguage {
  language: Language;
}

export interface SetModal {
  modal: Modal;
}

export interface SetTheme {
  theme: Theme;
}

export interface SetData {
  data: DataSchema;
}

export type ActionsMap = {
  [SET_IS_DATA_LOADED]: SetData;
  [SET_FINISHED_SCROLLING]: null;
  [SET_HIDE_LANDING_PAGE]: null;
  [SET_LANDING_PAGE_IMAGES]: SetLandingPageImages;
  [SET_LANGUAGE]: SetLanguage;
  [SET_MODAL]: SetModal;
  [SET_THEME]: SetTheme;
};

export type Actions = {
  [Key in keyof ActionsMap]: {
    type: Key;
    payload: ActionsMap[Key];
  };
}[keyof ActionsMap];

//**CONTEXT**//
export type Dispatcher = <Type extends Actions["type"], Payload extends ActionsMap[Type]>(
  type: Type,
  ...payload: Payload extends undefined ? [undefined?] : [Payload]
) => void;

export type GlobalContextInterface = readonly [GlobalState, Dispatcher];

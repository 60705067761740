import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { useExhibitionPageStyle } from "./styles";
import { Exhibition } from "./types";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Variant } from "@mui/material/styles/createTypography";
import { useGlobal } from "../../state/global/useGlobal";
import { ExhibitionSchema } from "../../schemas";
import { useHideHeader } from "../../hooks/useUtils/useUtils";
import { Languages } from "../../state/global/global.types";

const useExhibitionsPage = () => {
  const { data } = useGlobal();
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const { hide } = useHideHeader();
  const styles = useExhibitionPageStyle(theme, screenSize, hide);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  const getPropertyByLanguages = useCallback((exhibition: ExhibitionSchema, property: string) => {
    const props: string[] = [];
    Object.keys(Languages).forEach((l) => {
      const prop = property + l;
      props.push(prop);
    });
    return props.reduce((ac, a) => ({ ...ac, [a]: exhibition[a] }), {});
  }, []);

  const exhibitions = useMemo((): Exhibition[] | undefined => {
    if (!data.exhibitions) {
      return;
    }
    return data.exhibitions
      .map((exhSchema) => {
        const date = new Date(exhSchema.date_at);
        return {
          id: exhSchema.id,
          date_at: exhSchema.date_at,
          title: getPropertyByLanguages(exhSchema, "title_"),
          content: getPropertyByLanguages(exhSchema, "content_"),
          is_collective: exhSchema.is_collective,
          year: date.getFullYear(),
        };
      })
      .sort((a, b) => (a.year > b.year ? -1 : 1));
  }, [data.exhibitions]);

  const exhibitionsByYear = useMemo(() => {
    if (!exhibitions) {
      return;
    }
    const uniqueYearObj: {
      [key: string]: Exhibition[];
    } = exhibitions
      .map((item) => item.year)
      .filter((value, index, self) => self.indexOf(value) === index)
      .reduce((accumulator, value) => {
        return { ...accumulator, [value]: [] };
      }, {});
    exhibitions.forEach((ex: Exhibition) => uniqueYearObj[ex.year].push(ex));
    return uniqueYearObj;
  }, [exhibitions]);

  const variant: Variant = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs" ? "body2" : "body1";
  }, [screenSize]);

  return {
    exhibitionsByYear,
    img: "https://valerioberruti.com/media/work_images/EB5A5290-2.jpg",
    overlayTxt: t("label.exhibitions"),
    variant,
    loading,
    styles,
    t,
    onLoadImage,
  };
};

export default useExhibitionsPage;

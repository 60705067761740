import { Style } from "../../globalTypes";
import { Theme } from "@mui/material";
import { ScreenSize } from "../../hooks/useDimensions/types";
import { useMemo } from "react";

export const useExhibitionPageStyle = (theme: Theme, screenSize: ScreenSize, hide: boolean): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const { matches: isLandscape } = window.matchMedia("(orientation: landscape)");

  const heightScene = useMemo(() => {
    if (isLandscape && isSmall) {
      return "100vh";
    }
    return isSmall ? "55vh" : "60vh";
  }, [isSmall, isLandscape]);

  return {
    container: { backgroundColor: theme.palette.background.default },
    logoContainer: {
      position: "absolute",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 16,
      paddingRight: 16,
      zIndex: "1000",
    },
    logo: {
      height: isSmall ? "40px" : "66px",
    },
    containerHeader: {
      height: isSmall ? "100vh" : "80vh",
      width: "100%",
      overflow: "scroll",
      opacity: hide ? 0 : 1,
    },
    sceneContainer: {
      height: heightScene,
      width: "100vw",
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },
    titleContainer: {
      position: "absolute",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      marginTop: isSmall ? -40 : -72,
    },
    icon: {
      width: isSmall ? "0.8em" : "0.8em",
      marginLeft: 4,
      marginRight: 4,
      marginBottom: isSmall ? -8 : -5,
      color: theme.palette.primary.light,
    },
    titleStyle: {
      marginTop: isSmall ? 4 : 0,
    },
    border: {
      paddingBottom: isSmall ? "60px" : "72px",
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },
    overlayComponentStyle: {
      height: isSmall ? "100vh" : "80vh",
    },
    imageContainerStyle: {
      height: isSmall ? "100vh" : "80vh",
    },
    textStyle: {
      fontSize: isSmall ? "3rem" : "5rem",
    },
  };
};

import { Style } from "../../globalTypes";
import { Theme } from "@mui/material";
import { useMemo } from "react";

export const useContactPageStyle = (theme: Theme, isSmall?: boolean): Style => {
  const { matches: isLandscape } = window.matchMedia("(orientation: landscape)");

  const margin = useMemo(() => {
    if (isLandscape && isSmall) {
      return 60;
    }
    return 0;
  }, [isSmall, isLandscape]);

  return {
    container: { backgroundColor: theme.palette.background.default },
    contactText: {
      fontSize: isSmall ? "2.6em" : "4.2em",
      color: theme.palette.primary.light,
      marginTop: 32,
      marginBottom: 16,
    },
    formContainer: { display: "flex", flexDirection: "column", minHeight: "100vh" },
    logo: { position: "absolute" },
    componentContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: margin,
      marginBottom: margin,
    },
  };
};

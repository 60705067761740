import { Style } from "../../globalTypes";
import { Theme } from "@mui/material";
import { ScreenSize } from "../../hooks/useDimensions/types";

export const useAboutPageStyle = (theme: Theme, screenSize: ScreenSize, showHeader?: boolean): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  return {
    container: {
      height: "100vh",
      width: "100vw",
      backgroundColor: theme.palette.background.default,
    },
    background: {
      height: isSmall ? "62vh" : "80vh",
      width: "100%",
      overflow: "scroll",
      opacity: showHeader ? 1 : 0,
    },
    aboutMeContainer: {
      width: "100vw",
      overflowY: "scroll",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: isSmall ? 32 : 80,
      position: "relative",
      zIndex: 10,
    },
    bioText: {
      fontSize: isSmall ? "2.6em" : "4.2em",
      color: theme.palette.primary.light,
    },
    smallBio: {
      marginTop: 8,
    },
    bio: { display: "flex", marginTop: 16, color: theme.palette.primary.light },
    paragraphBio: {
      flex: 1,
      marginRight: 40,
    },
    containerVb: {
      position: "relative",
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100%",
      overflow: "scroll",
    },
    containerImg: {
      height: isSmall ? "62vh" : "80vh",
      width: "100vw",
      marginBottom: -4,
      overflow: "scroll",
    },
    line: {
      height: isSmall ? 0.5 : 1,
      background: isSmall ? "rgba(244, 240, 240)" : "rgba(244, 240, 240, 0.5)",
      filter: "blur(3px)",
    },
    containerHeader: {
      height: isSmall ? "62vh" : "80vh",
      width: "100%",
      overflow: "scroll",
    },
    imgContainer: {
      position: "fixed",
      height: isSmall ? "62vh" : "80vh",
      width: "100%",
    },
    imgS: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
  };
};

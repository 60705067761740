import { Paths } from "../../navigation/types";
import AnimatedPage from "../../components/templates/AnimatedPage";
import "./style.css";
import React, { useMemo } from "react";
import CustomTypography from "../../components/atoms/customTypography/customTypography";
import useAboutPage from "./useAboutPage";
import Footer from "../../components/molecules/footer/Footer";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import { createMarkup } from "../../hooks/useUtils/useUtils";

const AboutPage = () => {
  const { contentRef, loading, bioData, styles, isSmall, t, onLoadImage } = useAboutPage();

  if (!bioData) {
    return <></>;
  }

  const { image, bioContent } = bioData;

  const AboutMeComponent = useMemo(() => {
    const content = createMarkup(bioContent);
    return (
      <div id="about-me-container" ref={contentRef} style={styles.aboutMeContainer}>
        <CustomTypography style={styles.bioText}>Bio</CustomTypography>
        {isSmall ? (
          <div style={styles.smallBio}>
            <CustomTypography dangerouslySetInnerHTML={content} />
          </div>
        ) : (
          <div style={styles.bio}>
            <CustomTypography dangerouslySetInnerHTML={content} variant="h6" style={styles.paragraphBio} />
          </div>
        )}
      </div>
    );
  }, [isSmall, t]);

  const Image = useMemo(() => {
    return (
      <div style={styles.imgContainer}>
        <img alt={t("altHomeImage")} src={image} style={styles.imgS} onLoad={onLoadImage} />
      </div>
    );
  }, []);

  const SafariImageComponent = useMemo(() => {
    return (
      <div style={styles.containerHeader}>
        {loading && <LoadingSkeleton width="100%" height="100%" />}
        {Image}
      </div>
    );
  }, [loading]);

  return (
    <AnimatedPage key={Paths.About} style={styles.container}>
      <>
        <div style={styles.background}>{SafariImageComponent}</div>
        {AboutMeComponent}
        <Footer />
      </>
    </AnimatedPage>
  );
};

export default AboutPage;

import React, { useCallback, useMemo, useState } from "react";
import Slider from "react-slick";
import "./styles.css";
import PersistentDrawerLeft from "../../components/templates/drawer/Drawer";
import { useTheme } from "@mui/material";
import { usePrivateWorks } from "./usePrivateWorks";
import ErrorPage from "../errorPage/ErrorPage";
import LandingPage from "../landingPage/LandingPage";
import { ImageCatalog } from "../../schemas";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

interface IPrivateWorksI {
  index: number;
  image: ImageCatalog;
}

const PrivateWorksImg = (props: IPrivateWorksI) => {
  const { index, image } = props;
  const [loading, setLoading] = useState(true);
  const { screenSize } = useDimensions();
  const theme = useTheme();

  const height = useMemo(() => {
    if (screenSize === "xs") {
      return "40vh";
    }
    if (screenSize === "sm") {
      return "50vh";
    }
    return "90vh";
  }, [screenSize]);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  const Component = useMemo(() => {
    const el = document.querySelector('[data-index="0"]');

    return (
      <React.Fragment key={index}>
        {loading && <LoadingSkeleton key={"loader_" + index} width={el ? el.clientWidth - 48 : 0} height={height} />}
        <div key={index} id={"image_id_" + index} style={{ height: height, width: "100%", position: "relative" }}>
          <img key={image.id} src={image.img} alt={"image_alt_" + index} onLoad={onLoadImage} />
          <a
            href={`${image.img}`}
            download="file"
            target="_blank"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.primary.light,
              padding: 8,
              paddingBottom: 4,
              borderBottomLeftRadius: 4,
            }}
          >
            <CloudDownloadIcon />
          </a>
        </div>
      </React.Fragment>
    );
  }, [screenSize, loading]);

  return <>{Component}</>;
};

const PrivateWorks = () => {
  const theme = useTheme();
  const { images, error, loading, settings, windowPage, onMiniatureClick } = usePrivateWorks();

  const renderSlides = useMemo(() => {
    return images.map((image, index) => <PrivateWorksImg key={index} image={image} index={index} />);
  }, [images, windowPage]);

  return (
    <div style={{ width: "100vw", display: "flex", backgroundColor: theme.palette.background.paper }}>
      {error ? (
        <ErrorPage />
      ) : (
        <>
          {loading ? (
            <LandingPage showModel={false} />
          ) : (
            <PersistentDrawerLeft images={images} onMiniatureClick={onMiniatureClick}>
              <div
                id="slider_container"
                style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}
              >
                <Slider {...settings}>{renderSlides}</Slider>
              </div>
            </PersistentDrawerLeft>
          )}
        </>
      )}
    </div>
  );
};

export default PrivateWorks;

import { useCallback, useContext } from "react";
import { GlobalContext } from "./global.context";
import { SetData, SetLandingPageImages, SetLanguage, SetModal, SetTheme } from "./global.types";
import {
  SET_FINISHED_SCROLLING,
  SET_LANDING_PAGE_IMAGES,
  SET_IS_DATA_LOADED,
  SET_LANGUAGE,
  SET_MODAL,
  SET_THEME,
  SET_HIDE_LANDING_PAGE,
} from "./global.constants";

export function useGlobal() {
  const [
    {
      data,
      landingPageImages,
      isDataLoaded,
      finishedScrolling,
      hideLandingPage,
      language,
      modal,
      server_url,
      theme,
      version,
    },
    dispatch,
  ] = useContext(GlobalContext);

  const setLandingPageImages = useCallback(
    (payload: SetLandingPageImages) => dispatch(SET_LANDING_PAGE_IMAGES, payload),
    [dispatch],
  );
  const setIsDataLoaded = useCallback((payload: SetData) => dispatch(SET_IS_DATA_LOADED, payload), [dispatch]);
  const setFinishedScrolling = useCallback(() => dispatch(SET_FINISHED_SCROLLING, null), [dispatch]);
  const setHideLandingPage = useCallback(() => dispatch(SET_HIDE_LANDING_PAGE, null), [dispatch]);
  const setLanguage = useCallback((payload: SetLanguage) => dispatch(SET_LANGUAGE, payload), [dispatch]);
  const setModal = useCallback((payload: SetModal) => dispatch(SET_MODAL, payload), [dispatch]);
  const setTheme = useCallback((payload: SetTheme) => dispatch(SET_THEME, payload), [dispatch]);

  return {
    data,
    landingPageImages,
    isDataLoaded,
    finishedScrolling,
    hideLandingPage,
    language,
    modal,
    server_url,
    theme,
    version,
    setLandingPageImages,
    setIsDataLoaded,
    setFinishedScrolling,
    setHideLandingPage,
    setModal,
    setLanguage,
    setTheme,
  };
}

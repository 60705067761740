import useAxios from "axios-hooks";
import { ImageCatalog } from "../../../schemas";
import { AxiosError } from "axios";
import { makeApiUrl } from "../../landingPage/hooks/useAllDataLoader";

interface IUsePrivateWorksLoader {
  data?: ImageCatalog[];
  loading?: boolean;
  error?: AxiosError<boolean, boolean> | null;
}

export const usePrivateWorksLoader = (): IUsePrivateWorksLoader => {
  const [{ data: catalog, loading: loading, error: error }] = useAxios<ImageCatalog[], boolean, boolean>({
    url: makeApiUrl("/catalog"),
    method: "GET",
  });

  return {
    data: catalog,
    loading,
    error,
  };
};

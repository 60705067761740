import { Style } from "../../globalTypes";
import { ScreenSize } from "../../hooks/useDimensions/types";
import { Theme } from "@mui/material";

export const useLandingPageStyle = (theme: Theme, screenSize: ScreenSize): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  return {
    container: {
      width: "100%",
      height: "100vh",
      backgroundColor: "lightgray",
    },
    logoContainer: {
      position: "fixed",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: 16,
      paddingRight: 16,
      zIndex: "1000",
    },
    logo: {
      height: screenSize === "sm" || screenSize === "xs" ? "48px" : "66px",
    },
    containerLoader: {
      height: "100vh",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    logoLoader: {
      height: screenSize === "sm" || screenSize === "xs" ? "64px" : "96px",
    },
    linearProgress: {
      width: "100vw",
      height: isSmall ? "2px" : undefined,
      backgroundColor: theme.palette.primary.contrastText,
    },
    logoLoaderContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  };
};

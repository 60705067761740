import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Logo from "../Logo/Logo";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface IAppBarCustom {
  handleDrawerOpen: () => void;
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const AppBarCustom = (props: IAppBarCustom) => {
  const { handleDrawerOpen } = props;
  const theme = useTheme();

  return (
    <AppBar position="fixed" style={{ zIndex: 100, backgroundColor: theme.palette.background.paper }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          size="small"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            mr: 2,
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
            color: theme.palette.primary.light,
          }}
        >
          <ChevronRightIcon />
        </IconButton>
        <Logo
          containerStyle={{ position: "relative", padding: 0, display: "flex", justifyContent: "flex-end" }}
          imgStyle={{ height: 32 }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarCustom;

import { useGlobal } from "../../state/global/useGlobal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useProjectPageStyle } from "./styles";
import { useNavigation } from "../../navigation/useNavigation";
import { Paths } from "../../navigation/types";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { Variant } from "@mui/material/styles/createTypography";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import { Work } from "../../schemas";
import { Project } from "./types";

export const useProjectPage = (id?: string) => {
  const { setModal, data, language } = useGlobal();
  const { works } = data;
  const { navigate } = useNavigation();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const theme = useTheme();
  const styles = useProjectPageStyle(screenSize, theme);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const projectFromReducer = useMemo((): Work | undefined => {
    if (!id) {
      return;
    }
    return _.find(works, { id: parseInt(id) });
  }, [works, id]);

  const getPropertyByLanguage = useCallback(
    (property: string) => {
      if (!projectFromReducer) {
        return "";
      }
      const prop = property + language;
      return projectFromReducer[prop];
    },
    [projectFromReducer, language],
  );

  const project = useMemo((): Project | undefined => {
    if (!projectFromReducer) {
      return;
    }
    const date = new Date(projectFromReducer.publication_at);
    return {
      content: getPropertyByLanguage("content_"),
      id: projectFromReducer.id,
      images: projectFromReducer.images,
      image_thumbnail: projectFromReducer.image_thumbnail,
      x_thumbnail: projectFromReducer.x_thumbnail_position,
      y_thumbnail: projectFromReducer.y_thumbnail_position,
      place: projectFromReducer.place,
      year: date.getFullYear(),
      title: getPropertyByLanguage("title_"),
      video: projectFromReducer.video,
      embedded_link_video: projectFromReducer.embedded_link_video,
    };
  }, [projectFromReducer, language]);

  const setIsLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    return () => {
      setModal({ modal: { open: false } });
    };
  }, [setModal]);

  const onModalClose = useCallback(() => {
    setModal({ modal: { open: false } });
    navigate(Paths.Works + "/");
  }, [navigate, setModal]);

  const variantTitle: Variant = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs" ? "h4" : "h2";
  }, [screenSize]);

  const variantPlace: Variant = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs" ? "body2" : "body1";
  }, [screenSize]);

  return { project, loading, isSmall, styles, variantTitle, variantPlace, onModalClose, setIsLoaded };
};

import Modal from "../../components/templates/modal/Modal";
import React, { useMemo } from "react";
import { useArticlePage } from "./useArticlePage";
import { useParams } from "react-router-dom";
import CustomTypography from "../../components/atoms/customTypography/customTypography";
import PlaceIcon from "@mui/icons-material/Place";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import Footer from "../../components/molecules/footer/Footer";
import { createMarkup } from "../../hooks/useUtils/useUtils";
import CustomImages from "../../components/atoms/customImages/CustomImages";
import { Image } from "../../schemas";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

type ArticlePageParams = {
  articleId: string;
};

const ArticlePage = () => {
  const { articleId } = useParams<ArticlePageParams>();
  const { article, loading, onModalClose, isSmall, styles, variantTitle, variantPlace, setIsLoaded } =
    useArticlePage(articleId);

  if (!article) {
    return <></>;
  }

  const content = createMarkup(article.content);

  const ImagesComponent = useMemo(() => {
    const imageCover: Image = {
      id: 0,
      image: article.imageCover,
      image_alt: "image_cover",
      x_position: article.x_thumbnail_position,
      y_position: article.y_thumbnail_position,
    };
    return (
      <CustomImages
        images={article.images && article.images.length ? article.images : [imageCover]}
        style={styles.image}
        loading={loading}
        onLoadImage={setIsLoaded}
      />
    );
  }, [loading, article]);

  const HeaderArticlePage = () => {
    return (
      <div style={styles.placeContainer}>
        <CustomTypography variant={variantPlace} style={styles.headerText}>
          {isSmall && <FiberManualRecordIcon style={styles.icon} />}
          {article.data}
        </CustomTypography>
        {article.place &&
          (isSmall ? (
            <div style={styles.iconContainerSmall}>
              <PlaceIcon style={styles.icon} />
              <CustomTypography variant={variantPlace} style={styles.headerTextSmall}>
                {article.place}
              </CustomTypography>
            </div>
          ) : (
            <>
              <CustomTypography variant={variantPlace}>&nbsp; /</CustomTypography>
              <PlaceIcon style={styles.icon} />
              <CustomTypography variant={variantPlace} style={styles.headerText}>
                {article.place}
              </CustomTypography>
            </>
          ))}
      </div>
    );
  };

  const MainComponent = () => {
    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <HeaderArticlePage />
          <CustomTypography variant={variantTitle} style={styles.title}>
            {article.title}
          </CustomTypography>
        </div>
        <div style={styles.imgContainer}>
          {loading && <LoadingSkeleton width="100%" height="100%" />}
          {ImagesComponent}
        </div>
        <CustomTypography dangerouslySetInnerHTML={content} id="content" variant="h6" style={styles.description} />
        {article.video && (
          <div style={styles.videoContainer}>
            <video style={styles.video} controls>
              <source src={article.video} type="video/mp4" />
            </video>
          </div>
        )}
        {article.embedded_link_video && (
          <div style={styles.videoContainer}>
            <iframe
              style={styles.video}
              src={article.embedded_link_video}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )}
        <Footer padding={false} />
      </div>
    );
  };

  return <Modal Component={MainComponent} onClose={onModalClose} />;
};

export default ArticlePage;

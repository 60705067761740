import { Style } from "../../../globalTypes";
import { Theme } from "@mui/material";

export const useNavigationButtonStyle = (theme: Theme): Style => {
  return {
    btnContainer: {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      fontWeight: "light",
      fontSize: "0.85em",
      marginRight: 16,
      flexDirection: "column",
      color: theme.palette.primary.contrastText,
    },
    btnContainerSelected: {
      fontWeight: "bold",
    },
    btnContainerNoSelected: {
      marginBottom: 3,
      cursor: "pointer",
      opacity: 0.5,
    },
    lineContainer: {
      position: "relative",
      height: 3,
      marginRight: 10,
    },
    svg: { height: "100%", display: "block", width: "100%", position: "absolute", top: 0, left: 0 },
    path: {
      fill: "none",
      strokeWidth: 3,
      stroke: theme.palette.primary.contrastText,
    },
    cursor: {
      display: "flex",
      alignItems: "flex-end",
    },
  };
};

import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { useAboutPageStyle } from "./styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGlobal } from "../../state/global/useGlobal";
import { BioSchema } from "../../schemas";
import { useTranslation } from "react-i18next";

export interface VBImageProps {
  line?: boolean;
  effect?: string;
}

interface Bio {
  image: string;
  bioContent: string;
}

const effects = ["grayscaled", "saturated", "blur", "sepia", ""];

const useShowHeader = () => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState<boolean>(true);

  const set = useCallback(() => {
    setShow(!show);
  }, [show]);

  const scrollHandler = useCallback(() => {
    if (!contentRef.current) {
      return;
    }
    if (
      (contentRef.current.getBoundingClientRect().top > 0 && show) ||
      (contentRef.current.getBoundingClientRect().top < 0 && !show)
    ) {
      return;
    }
    set();
  }, [show]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, [show]);

  return {
    contentRef,
    show: show,
  };
};

const useAboutPage = () => {
  const { data, language } = useGlobal();
  const { bio } = data;
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const { contentRef, show } = useShowHeader();

  const theme = useTheme();
  const { screenSize } = useDimensions();

  const styles = useAboutPageStyle(theme, screenSize, show);

  const getPropertyByLanguage = useCallback(
    (bio: BioSchema, property: string) => {
      const prop = property + language;
      return bio[prop];
    },
    [language],
  );

  const bioData = useMemo((): Bio | undefined => {
    if (!bio) {
      return;
    }
    return {
      image: bio[0].image,
      bioContent: getPropertyByLanguage(bio[0], "content_"),
    };
  }, [bio, language]);

  const isSmall = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs";
  }, [screenSize]);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  return {
    contentRef,
    effects,
    loading,
    styles,
    bioData,
    isSmall,
    t,
    onLoadImage,
  };
};

export default useAboutPage;

import { useEffect, useMemo, useRef } from "react";
import { ITimelineStepProps } from "./types";
import { useTimelineStepStyle } from "./styles";
import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../../../hooks/useDimensions/useDimensions";
import { useGlobal } from "../../../../state/global/useGlobal";

const useTimelineStep = (props: ITimelineStepProps) => {
  const { setObserver, callback, filteredExhibitions, index, ...others } = props;
  const { id, exhibitions, show } = others;
  const { language } = useGlobal();
  const timelineRef = useRef(null);
  const circleRef = useRef(null);
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const styles = useTimelineStepStyle(theme, screenSize);

  const someCallback = () => {
    callback(id);
  };

  const year = useMemo(() => {
    return exhibitions[0] ? exhibitions[0].year : null;
  }, [exhibitions]);

  useEffect(() => {
    if (!timelineRef.current || !circleRef.current) {
      return;
    }
    const found = filteredExhibitions.find((ex) => ex.id === id);

    setObserver(timelineRef.current, found ? found.show : false);
    setObserver(circleRef.current, found ? found.show : false, someCallback);
  }, []);

  return { language, index, timelineRef, circleRef, year, show, exhibitions, styles };
};

export default useTimelineStep;

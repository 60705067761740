import config from "./application.json";
import { Paths } from "../navigation/types";
import { Language } from "../state/global/global.types";

export enum Buttons {
  Home = "Home",
  Works = "Works",
  Exhibitions = "Exhibitions",
  Diary = "Diary",
  About = "About",
  Contact = "Contact",
}

export interface NavButton {
  name: Buttons;
  icon: string;
  path: string;
  t: string;
}

const navButtons: NavButton[] = [
  {
    name: Buttons.Home,
    icon: "M13.83,4.05a2.59,2.59,0,0,0-3.66,0l-6.7,6.7a2.56,2.56,0,0,0-.76,1.83v5.47a2.66,2.66,0,0,0,2.65,2.66H9.61V14.14h4.78v6.57h4.25a2.66,2.66,0,0,0,2.65-2.66V12.58a2.56,2.56,0,0,0-.76-1.83Z",
    path: Paths.Home,
    t: "home",
  },
  {
    name: Buttons.Works,
    icon: "M8.75,15.45a6.54,6.54,0,0,0,2.4-1A38.1,38.1,0,0,0,15.94,11c.66-.56,6.48-5.51,5.16-6.62s-7.21,3.78-7.88,4.34-4.71,4-5.29,5.8a4.62,4.62,0,0,0-4.08,1.17C2.3,17,2.77,19.38,2.79,19.48a.37.37,0,0,0,.32.27,9.94,9.94,0,0,0,1.13.07,5.46,5.46,0,0,0,3.41-1,5.34,5.34,0,0,0,1-1.07l0,0a3.08,3.08,0,0,0,.27-1.7,2.32,2.32,0,0,0-.81-1.51l-.31-.11M20.54,4.82,18.85,8.3,16.68,6.05",
    path: Paths.Works,
    t: "works",
  },
  {
    name: Buttons.Exhibitions,
    icon: "M12,4.48c-6,0-10.61,6.67-10.8,7a1,1,0,0,0,0,1.12c.19.29,4.83,7,10.8,7s10.61-6.67,10.8-7a1,1,0,0,0,0-1.12C22.61,11.15,18,4.48,12,4.48Zm0,10.29A2.77,2.77,0,1,1,14.77,12,2.77,2.77,0,0,1,12,14.77Z",
    path: Paths.Exhibitions,
    t: "exhibitions",
  },
  {
    name: Buttons.Diary,
    icon: "M20.52,3.11H14.64A3.76,3.76,0,0,0,12,4.4,3.76,3.76,0,0,0,9.36,3.11H3.48a.77.77,0,0,0-.77.76V18.41a.77.77,0,0,0,.77.77h5.8a3,3,0,0,1,1.88.95l.19.24a.77.77,0,0,0,.18.18.77.77,0,0,0,.94,0,.77.77,0,0,0,.18-.18l.19-.24a3,3,0,0,1,1.88-.95h5.8a.77.77,0,0,0,.77-.77V3.87A.77.77,0,0,0,20.52,3.11ZM10,8.18H5.7m4.27,3H5.7m4.27,3H5.7M18.3,8.18H14m4.27,3H14m4.27,3H14",
    path: Paths.Diary,
    t: "diary",
  },
  {
    name: Buttons.About,
    icon: "M12,20.93C7.71,20.93,4,20.44,4,18.5s3.64-3.73,8-3.73,8,1.77,8,3.71S16.31,20.93,12,20.93Zm0-9.15a4,4,0,1,0-4-4,4,4,0,0,0,4,4Z",
    path: Paths.About,
    t: "about",
  },
  {
    name: Buttons.Contact,
    icon: "M12,20.93C7.71,20.93,4,20.44,4,18.5s3.64-3.73,8-3.73,8,1.77,8,3.71S16.31,20.93,12,20.93Zm0-9.15a4,4,0,1,0-4-4,4,4,0,0,0,4,4Z",
    path: Paths.Contact,
    t: "contact",
  },
];

interface PageTitle {
  [key: string]: string;
}

export const pageTitles: PageTitle = {
  Home: "Valerio Berruti",
  [Paths.Home]: "Valerio Berruti - Home",
  [Paths.Works]: "Valerio Berruti - Works",
  [Paths.Exhibitions]: "Valerio Berruti - Exhibitions",
  [Paths.Diary]: "Valerio Berruti - Diary",
  [Paths.About]: "Valerio Berruti - About",
  [Paths.Works + "/"]: "Valerio Berruti - Project",
  [Paths.Diary + "/"]: "Valerio Berruti - Article",
  [Paths.Home + "/"]: "Valerio Berruti - Article",
  [Paths.Contact]: "Valerio Berruti - Contact",
  [Paths.PrivateWorks]: "Valerio Berruti - Catalog",
  [Paths.Alba]: "Valerio Berruti - Alba",
};

export const getAvailableLanguages = (): Language[] => {
  return config.languages.available as Language[];
};

export const getNavbarButtons = (): NavButton[] => {
  return navButtons;
};

export default config;

import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Paths } from "./types";
import WorksPage from "../pages/worksPage/WorksPage";
import ProjectPage from "../pages/projectPage/projectPage";
import { Modal } from "../state/global/global.types";
import ExhibitionsPage from "../pages/exhibitionsPage/ExhibitionsPage";
import AboutPage from "../pages/aboutPage/AboutPage";
import DiaryPage from "../pages/diaryPage/DiaryPage";
import ArticlePage from "../pages/articlePage/ArticlePage";
import HomePage from "../pages/homePage/HomePage";
import ContactPage from "../pages/contactPage/ContactPage";
import PrivateWorks from "../pages/privateWorks/PrivateWorks";
import AlbaPage from "../pages/albaPage/AlbaPage";

interface IMainRoutes {
  modal: Modal;
}

const useChangeThemeColor = () => {
  if (document.querySelector("meta[name='theme-color']")) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    document.querySelector("meta[name='theme-color']").content = "#F4F0F0FF";
  }
};

export const MainRoutes = (props: IMainRoutes) => {
  const { modal } = props;
  useChangeThemeColor();

  useEffect(() => {
    const jQueryElem = document.getElementById("jquery-js");
    if (jQueryElem) {
      jQueryElem.remove();
    }
    const vendorElem = document.getElementById("sage/vendors.js-js");
    if (vendorElem) {
      vendorElem.remove();
    }
  }, []);

  useEffect(() => {
    document.body.style.overflowY = modal.open ? "hidden" : "visible";
  }, [modal.open]);

  return (
    <>
      <Routes>
        <Route path={Paths.Home} element={<HomePage key={Paths.Home} />}>
          <Route path={Paths.ArticleHome} element={<ArticlePage key={Paths.ArticleHome} />} />
        </Route>
        <Route path={Paths.Works} element={<WorksPage key={Paths.Works} />}>
          <Route path={Paths.Project} element={<ProjectPage key={Paths.Project} />} />
        </Route>
        <Route path={Paths.Exhibitions} element={<ExhibitionsPage key={Paths.Exhibitions} />} />
        <Route path={Paths.Diary} element={<DiaryPage key={Paths.Diary} />}>
          <Route path={Paths.Article} element={<ArticlePage key={Paths.Article} />} />
        </Route>
        <Route path={Paths.About} element={<AboutPage key={Paths.About} />} />
        <Route path={Paths.Contact} element={<ContactPage key={Paths.Contact} />} />
        <Route path="*" element={<Navigate to={Paths.Home} replace />} />
      </Routes>
      {modal.open && (
        <Routes>
          <Route path={Paths.Project} element={<ProjectPage key={Paths.Project} />} />
          <Route path={Paths.Article} element={<ArticlePage key={Paths.Article} />} />
          <Route path={Paths.ArticleHome} element={<ArticlePage key={Paths.Article} />} />
        </Routes>
      )}
      {!modal.open && (
        <Routes>
          <Route path={Paths.Project} element={<ProjectPage key={Paths.Project} />} />
          <Route path={Paths.Article} element={<ArticlePage key={Paths.Article} />} />
          <Route path={Paths.ArticleHome} element={<ArticlePage key={Paths.Article} />} />
        </Routes>
      )}
    </>
  );
};

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path={Paths.Alba} element={<AlbaPage key={Paths.Alba} />} />
      <Route path={Paths.PrivateWorks} element={<PrivateWorks key={Paths.PrivateWorks} />} />
      <Route path="*" element={<Navigate to={Paths.Home} replace />} />
    </Routes>
  );
};

import { Style } from "../../../globalTypes";
import { ScreenSize } from "../../../hooks/useDimensions/types";

export const useLogoStyle = (screenSize: ScreenSize): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";

  return {
    logoContainer: {
      position: "fixed",
      width: "100%",
      display: "flex",
      justifyContent: isSmall ? "flex-start" : "flex-end",
      paddingTop: 24,
      paddingRight: isSmall ? 0 : 24,
      paddingLeft: isSmall ? 24 : 0,
      zIndex: "100",
    },
    logo: {
      height: isSmall ? "48px" : "66px",
    },
  };
};

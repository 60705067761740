import logo from "../../assets/images/vb-logo.png";
import { useMemo } from "react";

interface IAlbaPageHtmlComponent {
  visible: boolean;
}

const AlbaPageHtmlComponent = (props: IAlbaPageHtmlComponent) => {
  const { visible } = props;
  const Component = useMemo(() => {
    const style = {
      opacity: visible ? 1 : 0,
    };
    return (
      <div id="js-pjax-wrapper" style={{ fontFamily: "NeueHaasGroteskDisp Pro", fontWeight: 400, ...style }}>
        <div className="js-pjax-container" data-template="home">
          <header className="c-header -sticky js-header">
            <ul className="c-header_menu">
              <li className="c-header_menu_item">
                <a
                  href="#ferrero_scroll"
                  className="no-transition js-scrollto"
                  data-offset="60"
                  data-module="Event"
                  data-event-action="click"
                  data-event-category="Menu"
                  data-event-label="#ferrero_scroll"
                >
                  Ferrero
                </a>
              </li>
              <li className="c-header_menu_item">
                <a
                  href="#links_scroll"
                  className="no-transition js-scrollto"
                  data-offset="60"
                  data-module="Event"
                  data-event-action="click"
                  data-event-category="Menu"
                  data-event-label="#links_scroll"
                >
                  Links
                </a>
              </li>
            </ul>
            <a href="https://www.valerioberruti.com" className="c-header_logo" data-pjax-state="">
              <span className="u-screen-reader-text">VALERIO BERRUTI</span>
              <img src={logo} />
            </a>
            <button className="c-header_burger u-none@from-small" type="button" data-module="NavButton"></button>
          </header>

          <div
            className="o-scroll"
            data-horizontal=""
            data-module="Scroll"
            style={{ transform: "translate3d(0px, 0px, 0px)" }}
          >
            <div className="o-scroll_section" style={{ backgroundColor: "white" }}>
              <div className="c-promo" data-module="Carousel">
                <div
                  className="c-promo_image_wrapper o-image_wrapper js-animate js-carousel swiper-container swiper-container-horizontal is-show"
                  style={{ cursor: "grab" }}
                >
                  <div className="swiper-wrapper">
                    <div
                      className="o-image swiper-slide || js-carousel-slide swiper-slide-active"
                      style={{ width: "608px" }}
                    >
                      <div
                        className="c-promo_image js-animate js-lazy-background is-show"
                        data-speed="-1"
                        data-position="top"
                      >
                        <img
                          src={"img/first.jpg"}
                          style={{
                            transform: "translate3d(0px, 0px, 0px)",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </div>

                <div
                  className="c-promo_content_wrapper js-animate swiper-container js-carousel-content swiper-container-horizontal is-show"
                  style={{ cursor: "grab" }}
                >
                  <div className="c-promo_content swiper-wrapper">
                    <a
                      href="https://www.valerioberruti.com"
                      className="c-promo_content_slide js-scrollto no-transition swiper-slide swiper-slide-active"
                      data-offset="60"
                      style={{ width: "270px" }}
                    >
                      <div className="c-promo_content_inner">
                        <div className="c-promo_title">
                          <small>
                            <p className="c-promo_title_line">Piazza Michele Ferrero</p>
                          </small>
                          <p className="c-promo_title_line">“Alba”</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <span className="swiper-notification" aria-live="assertive" aria-atomic="true"></span>
                </div>
              </div>
            </div>

            <div className="o-scroll_section c-header_wrapper" style={{ backgroundColor: "white" }}>
              <span
                className="c-header_trigger js-animate is-show"
                data-callback="trigger.Header()"
                data-repeat=""
              ></span>
              <header className="c-header js-header">
                <ul className="c-header_menu">
                  <li className="c-header_menu_item">
                    <a
                      href="#ferrero_scroll"
                      className="no-transition js-scrollto"
                      data-offset="60"
                      data-module="Event"
                      data-event-action="click"
                      data-event-category="Menu"
                      data-event-label="#ferrero_scroll"
                    >
                      Ferrero
                    </a>
                  </li>
                  <li className="c-header_menu_item">
                    <a
                      href="#links_scroll"
                      className="no-transition js-scrollto"
                      data-offset="60"
                      data-module="Event"
                      data-event-action="click"
                      data-event-category="Menu"
                      data-event-label="#links_scroll"
                    >
                      Links
                    </a>
                  </li>
                </ul>
                <a href="https://valerioberruti.com" className="c-header_logo" data-pjax-state="">
                  <span className="u-screen-reader-text">Valerio Berruti</span>
                  <img src={logo} />
                </a>
                <button className="c-header_burger u-none@from-small" type="button" data-module="NavButton"></button>
              </header>
            </div>

            <div className="o-scroll_section" style={{ backgroundColor: "white" }}>
              <section className="c-home-intro">
                <div className="c-home-intro_summary js-animate u-text is-show">
                  <p style={{ fontSize: "1.2rem" }}>
                    Grazie al dono della famiglia Ferrero alla Città di Alba, si è arricchita di una scultura
                    monumentale, un simbolo dello stretto legame tra la Famiglia Ferrero e la città di Alba, luogo in
                    cui è iniziata la storia del colosso dolciario internazionale, nel piccolo laboratorio di via
                    Rattazzi.
                  </p>
                </div>

                <div className="c-home-intro_lines">
                  <div className="c-home-intro_lines_item">
                    <span className="c-home-intro_lines_split" data-module="SplittedText" data-horizontal="">
                      <span>“</span>
                      <span>A</span>
                      <span>l</span>
                      <span>b</span>
                      <span>a</span>
                      <span>”</span>
                    </span>
                  </div>
                </div>
              </section>
            </div>

            <div className="o-scroll_section -under">
              <div className="c-slider" data-module="Slider">
                <div className="c-slider_background js-slider js-animate" data-speed="-1">
                  <div className="c-slider_background_item js-slider-item is-active">
                    <div className="c-slider_background_item_inner o-image_wrapper js-animate">
                      <figure className="o-image">
                        <div className="c-slider_background_item_image js-lazy-background project"></div>
                      </figure>
                    </div>
                  </div>

                  <div className="c-slider_background_item js-slider-item is-active">
                    <div className="c-slider_background_item_inner o-image_wrapper js-animate">
                      <figure className="o-image">
                        <div className="c-slider_background_item_image js-lazy-background project1"></div>
                      </figure>
                    </div>
                  </div>

                  <div className="c-slider_background_item js-slider-item">
                    <div className="c-slider_background_item_inner o-image_wrapper js-animate">
                      <figure className="o-image">
                        <div className="c-slider_background_item_image js-lazy-background preparatorio"></div>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="o-scroll_section" id="ferrero_scroll" style={{ backgroundColor: "white" }}>
              <section className="c-block-content">
                <div className="c-block-content_summary js-animate">
                  <p>
                    Così la signora Maria Franca Ferrero:{" "}
                    <i>
                      <br />
                      “Sono lieta, con mio figlio Giovanni e tutta la mia famiglia, di donare alla piazza che ricorda la
                      figura e la personalità di mio marito Michele un’opera di un artista albese di respiro
                      internazionale come Valerio Berruti. Ringrazio il Sindaco, Carlo Bo, e l’Amministrazione tutta per
                      la costante disponibilità al dialogo e l’attenzione nei confronti della memoria di Michele.
                      <br />
                      Vorrei che l’opera di Berruti potesse rappresentare un affettuoso richiamo a quei sentimenti e
                      valori che mio marito considerava primari: la fantasia, la libertà, la curiosità verso il nuovo e
                      verso il futuro, il senso della famiglia e della comunità, che si amplia oltre ogni orizzonte, non
                      solamente geografico”
                    </i>
                    .
                  </p>
                </div>
              </section>
            </div>

            <div className="o-scroll_section" id="links_scroll" style={{ backgroundColor: "white" }}>
              <div className="c-brand-list_wrapper js-animate" data-module="BrandList">
                <ul className="c-brand-list">
                  <li className="c-brand-list_item">
                    <a
                      href="#ferrero_scroll"
                      className="js-link js-scrollto no-transition"
                      data-offset="60"
                      data-id="brand_101"
                      data-module="Event"
                      data-event-action="click"
                      data-event-category="Brands List"
                      data-event-label="Fondazione Ferrero"
                    >
                      {" "}
                      Fondazione Ferrero{" "}
                    </a>
                  </li>
                  <li className="c-brand-list_item">
                    <a
                      href="#brand_102"
                      className="js-link js-scrollto no-transition"
                      data-offset="60"
                      data-id="brand_102"
                      data-module="Event"
                      data-event-action="click"
                      data-event-category="Brands List"
                      data-event-label="Comune di Alba"
                    >
                      {" "}
                      Comune di Alba{" "}
                    </a>
                  </li>

                  <li className="c-brand-list_item">
                    <a
                      href="#brand_103"
                      className="js-link js-scrollto no-transition"
                      data-offset="60"
                      data-id="brand_103"
                      data-module="Event"
                      data-event-action="click"
                      data-event-category="Brands List"
                      data-event-label="Comune di Alba"
                    >
                      {" "}
                      Installazione{" "}
                    </a>
                  </li>
                </ul>
                <div className="c-brand-list_backgrounds">
                  <figure
                    className="c-brand-list_background js-lazy-background js-background fondazione_ferrero"
                    data-id="brand_101"
                  />

                  <figure
                    className="c-brand-list_background js-lazy-background js-background alba-logo"
                    data-id="brand_102"
                  />

                  <figure
                    className="c-brand-list_background js-lazy-background js-background install1"
                    data-id="brand_103"
                  />
                </div>
              </div>
            </div>

            <div className="o-scroll_section" id="brand_102" style={{ backgroundColor: "white" }}>
              <section className="c-brand">
                <h1 className="c-brand_name c-rail_wrapper" data-module="Rail" data-rail-speed="1">
                  <div
                    className="c-rail_group-container"
                    style={{ zIndex: 0, transform: "translate3d(-1335px, 0px, 0px)" }}
                  >
                    <div className="c-rail_track-container">
                      <div className="c-rail_track">
                        <span>Comune</span>
                      </div>
                      <div className="c-rail_track">
                        <span>Alba</span>
                      </div>
                      <div className="c-rail_track">
                        <span>Comune</span>
                      </div>
                    </div>
                    <div className="c-rail_track-container">
                      <div className="c-rail_track">
                        <span>Alba</span>
                      </div>
                      <div className="c-rail_track">
                        <span>Comune</span>
                      </div>
                      <div className="c-rail_track">
                        <span>Alba</span>
                      </div>
                    </div>
                  </div>
                </h1>
                <div className="c-brand_images">
                  <div className="o-layout c-brand_images_grid">
                    <div className="o-layout_item u-2/3@from-medium c-brand_images_col -center -big">
                      <div className="c-brand_image_wrapper o-image_wrapper js-animate">
                        <figure className="c-brand_image o-image js-lazy-background covered" />
                      </div>
                    </div>
                    <div
                      className="o-layout_item u-1/3@from-medium c-brand_images_col -bottom -small js-animate"
                      data-speed="-0.8"
                    >
                      <div className="c-brand_image_wrapper o-image_wrapper js-animate">
                        <figure className="c-brand_image o-image js-lazy-background see" />
                      </div>
                    </div>
                  </div>
                  <figure className="c-brand_logo_wrapper -bottom">
                    <a href="https://www.comune.alba.cn.it" target="_blank" className="c-brand_logo" data-pjax-state="">
                      <img src={"img/alba_logo.png"} />
                    </a>
                  </figure>
                </div>
                <div className="c-brand_content -top">
                  <div className="c-brand_content_inner">
                    <p className="u-text js-animate c-brand_content_text is-show">
                      Il sindaco di Alba Carlo Bo:{" "}
                      <i>
                        “Un grazie sincero alla signora Maria Franca Ferrero, al figlio Giovanni e a tutta la famiglia,
                        per questo dono che hanno voluto rivolgere alla nostra città. Un’opera artistica importante che
                        si collocherà nella piazza dedicata alla memoria del signor Michele, un luogo a cui gli albesi
                        sono profondamente legati e che rappresenta un centro dello sviluppo sociale ed economico della
                        nostra città. Questa scelta condivisa è l’emblema della visione del futuro che ha caratterizzato
                        la storia della Ferrero, percorso a cui vogliamo continuare a ispirarci e che trasformerà la
                        piazza in uno spazio d’arte frutto del desiderio comune di diventare sempre più protagonisti
                        della scena internazionale, partendo dai luoghi della tradizione e della storia cittadina”.
                        “Sono onorato e felice di ricevere questo incarico – dichiara l’artista Valerio Berruti - perché
                        Alba è la città in cui sono nato e soprattutto è il luogo in cui ho scelto di vivere."
                      </i>
                    </p>
                  </div>
                </div>
              </section>
            </div>

            <div className="o-scroll_section" id="brand_103">
              <section className="c-brand -dark">
                <h1 className="c-brand_name c-rail_wrapper" data-module="Rail" data-rail-speed="1">
                  <div
                    className="c-rail_group-container"
                    style={{ zIndex: 0, transform: "translate3d(-629px, 0px, 0px)" }}
                  >
                    <div className="c-rail_track-container">
                      <div className="c-rail_track">
                        <span>Installazione</span>
                      </div>
                      <div className="c-rail_track">
                        <span>Installazione</span>
                      </div>
                    </div>
                    <div className="c-rail_track-container">
                      <div className="c-rail_track">
                        <span>Installazione</span>
                      </div>
                      <div className="c-rail_track">
                        <span>Installazione</span>
                      </div>
                    </div>
                  </div>
                </h1>

                <div className="c-brand_images">
                  <div className="o-layout c-brand_images_grid">
                    <div className="o-layout_item u-2/3@from-medium c-brand_images_col -top -standard">
                      <div className="c-brand_image_wrapper o-image_wrapper js-animate">
                        <figure className="c-brand_image o-image js-lazy-background install1" />
                      </div>
                    </div>
                    <div
                      className="o-layout_item u-1/3@from-medium c-brand_images_col -bottom -standard js-animate"
                      data-speed="-0.8"
                    >
                      <div className="c-brand_image_wrapper o-image_wrapper js-animate">
                        <figure className="c-brand_image o-image js-lazy-background install2" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="c-brand_content -top">
                  <div className="c-brand_content_inner">
                    <p
                      className="u-text js-animate c-brand_content_text"
                      style={{ fontSize: "1.3rem", color: "white", marginBottom: "2rem" }}
                    >
                      La scultura monumentale alta dodici metri e mezzo è realizzata in acciaio inox. Il nome dell’opera
                      è “Alba”, un evidente omaggio alla terra su cui si erge e al tempo stesso un nome di bimba per
                      enfatizzare l’aspetto poetico della capitale delle Langhe. Valerio Berruti per sottolineare il
                      carattere tipico di quella che Beppe Fenoglio chiamava “la razza langhetta” ha voluto
                      rappresentare una bambina raffigurata in un atteggiamento timido, ritroso o, come si direbbe nel
                      dialetto piemontese, “genato”. La scultura è la trascrizione tridimensionale del segno a matita di
                      Berruti: un ’impronta delicata che nonostante le dimensioni monumentali non impatta sull’ambiente
                      in maniera invasiva, ma anzi dialoga con lo spazio circostante accogliendolo al suo interno.
                      L’opera è un monumento alla vita, un monito rivolto allo spettatore che intende riportarlo al
                      periodo dell’infanzia, il momento della vita in cui tutto può ancora avvenire.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }, [visible]);
  return Component;
};

export default AlbaPageHtmlComponent;

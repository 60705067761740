import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigationBar } from "./useNavigationBar";
import MobileNavigator from "./MobileNavigator/MobileNavigator";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import MButton from "../../../atoms/MenuButton/MButton";

const MobileNavigationBar: React.FC = () => {
  const {
    buttons,
    open,
    selectedIndex,
    showButton,
    styles,
    styleBar,
    animate,
    menuVariants,
    handleClickMenu,
    handleClickRoute,
  } = useNavigationBar();
  const theme = useTheme();

  const NavBarHeader = () => {
    return (
      <motion.header id="navbar-header" style={{ ...styles.navbarHeaderContainer, ...styleBar }}>
        <Box component="div" style={styles.buttonContainer}>
          <MButton
            isOpen={open}
            onClick={handleClickMenu}
            strokeWidth="1.5"
            color={theme.palette.primary.light}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            width="32"
            height="24"
          />
        </Box>
      </motion.header>
    );
  };

  return (
    <div style={showButton ? undefined : { opacity: 0 }}>
      <NavBarHeader />
      <motion.nav
        style={styles.navbar}
        initial={false}
        variants={menuVariants}
        animate={animate}
        onClick={handleClickMenu}
      >
        <Box component="div" id="container" style={styles.container}>
          <MobileNavigator buttons={buttons} handleClickRoute={handleClickRoute} selectedIndex={selectedIndex} />
        </Box>
      </motion.nav>
    </div>
  );
};

export default MobileNavigationBar;

import { Style } from "../../globalTypes";
import { Theme } from "@mui/material";

export const useWorksPageStyle = (theme: Theme, isSmall?: boolean, hide?: boolean): Style => {
  return {
    container: { backgroundColor: theme.palette.background.default },
    containerHeader: {
      height: isSmall ? "100vh" : "80vh",
      width: "100%",
      overflow: "scroll",
      opacity: hide ? 0 : 1,
    },
    gridContainer: {
      position: "relative",
      paddingBottom: 32,
      backgroundColor: theme.palette.background.paper,
      zIndex: 100,
      marginTop: 0,
      paddingTop: 8,
      paddingLeft: 32,
      paddingRight: 32,
    },
    overlayComponentStyle: {
      height: isSmall ? "100vh" : "80vh",
    },
    imageContainerStyle: {
      height: isSmall ? "100vh" : "80vh",
    },
    modalBcg: {
      position: "absolute",
      width: "100vw",
      height: `10000px`,
      //backdropFilter: "blur(2px)",
      backgroundColor: "rgba(29, 27, 27, 0.4)",
      zIndex: "1000",
    },
  };
};

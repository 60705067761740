import React, { useCallback, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";
import { useLegendComponentStyle } from "./styles";
import { useTranslation } from "react-i18next";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";

interface ILegendComponent {
  selectedCategory: "personal" | "collective";
  onCategoryChange: () => void;
}

type Variant = {
  personal: "text" | "outlined" | "contained";
  collective: "text" | "outlined" | "contained";
};

const LegendComponent = (props: ILegendComponent) => {
  const { selectedCategory, onCategoryChange } = props;
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const styles = useLegendComponentStyle(theme, isSmall);
  const { t } = useTranslation();

  const direction = isSmall ? "column" : "row";

  const variant = useMemo((): Variant => {
    return selectedCategory === "personal"
      ? {
          personal: "contained",
          collective: "outlined",
        }
      : {
          collective: "contained",
          personal: "outlined",
        };
  }, [selectedCategory]);

  const getStyle = (variant: "text" | "outlined" | "contained") => {
    return variant === "contained" ? styles.buttonC : styles.buttonO;
  };

  const handleClick = useCallback(
    (category: "personal" | "collective") => {
      if (selectedCategory === category) {
        return;
      }
      onCategoryChange();
    },
    [selectedCategory],
  );

  return (
    <div style={styles.container}>
      <Stack direction={direction} spacing={2}>
        <Button
          variant={variant.personal}
          disableRipple
          disableElevation
          size="large"
          style={getStyle(variant.personal)}
          onClick={() => handleClick("personal")}
        >
          {t("label.personal")}
        </Button>
        <Button
          variant={variant.collective}
          disableRipple
          disableElevation
          size="large"
          style={getStyle(variant.collective)}
          onClick={() => handleClick("collective")}
        >
          {t("label.collective")}
        </Button>
      </Stack>
    </div>
  );
};

export default LegendComponent;

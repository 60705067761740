import * as React from "react";
import { useMemo } from "react";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";

export const useDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const { screenSize } = useDimensions();

  const isSmall = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs";
  }, [screenSize]);

  const drawerWidth = useMemo(() => {
    return isSmall ? 180 : 240;
  }, [isSmall]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onMainClick = () => {
    setOpen(false);
  };

  return {
    drawerWidth,
    open,
    handleDrawerOpen,
    handleDrawerClose,
    onMainClick,
  };
};

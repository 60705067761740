import { useCallback, useEffect, useRef, useState } from "react";
import { useGlobal } from "../../state/global/useGlobal";
import { useLandingPageDataLoader } from "../../pages/landingPage/hooks/useLandingPagePageDataLoader";
import { useTranslation } from "react-i18next";
import { Language } from "../../state/global/global.types";
import { IsFirstRender } from "./types";
import { matchRoutes, useLocation } from "react-router-dom";
import { Paths } from "../../navigation/types";
import { pageTitles } from "../../config/config";
import { useAllDataLoader } from "../../pages/landingPage/hooks/useAllDataLoader";
import { useDimensions } from "../useDimensions/useDimensions";

export const usePageTitle = () => {
  const path = useGetPath();
  useEffect(() => {
    if (!path) {
      document.title = pageTitles["Home"];
      return;
    }

    document.title = pageTitles[path] ? pageTitles[path] : pageTitles["/" + path.split("/")[1] + "/"];
  }, [path]);
};
export const useInterval = (callback: any, delay: any) => {
  const intervalId = useRef(0);
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => savedCallback.current();

    intervalId.current = window.setInterval(tick, delay);
    return () => window.clearInterval(intervalId.current);
  }, [delay]);

  return intervalId.current;
};

export const useIsFirstRender = (): IsFirstRender => {
  const isFirst = useRef(true);

  const createObj = (value: boolean): IsFirstRender => {
    return { first: value };
  };

  if (isFirst.current) {
    isFirst.current = false;

    return createObj(true);
  }

  return createObj(isFirst.current);
};

export const useLoadingData = () => {
  useLandingPageDataLoader(); //** THIS HOOK MANAGES LOADING LANDING PAGE IMAGES **//
  const { error } = useAllDataLoader();
  return { error };
};

export const useLanguage = () => {
  const { language, setLanguage } = useGlobal();
  const { i18n } = useTranslation();
  const browserLang = window.navigator.language.split("-")[0] as Language;

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [language]);

  useEffect(() => {
    if (language) return;
    if (browserLang !== language) {
      setLanguage({ language: browserLang });
    }
  }, [browserLang, language, setLanguage]);

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);
};

export const useRetryUntilResolved = (callback: any, interval = 100) => {
  const [hasResolved, setHasResolved] = useState(false);
  const path = useGetPath();

  useEffect(() => {
    setHasResolved(false);
  }, [path]);
  useInterval(
    () => {
      const result = callback();
      if (result) {
        setHasResolved(true);
      }
    },
    hasResolved ? null : interval,
  );
  return hasResolved;
};

export const useHideShowOnScroll = (scrolledComponentId: string, delay: number, typeEvent = "scroll") => {
  const isResolved = useRetryUntilResolved(() => !!document.getElementById(scrolledComponentId));
  const [isVisible, setIsVisible] = useState(true);

  const setNotVisible = useCallback(() => {
    if (!isVisible) {
      return;
    }
    setIsVisible(false);
  }, [isVisible]);

  useEffect(() => {
    if (!isResolved) {
      return;
    }
    const d = document.getElementById(scrolledComponentId);
    d && d.addEventListener(typeEvent, setNotVisible);
    return () => {
      d && d.removeEventListener(typeEvent, setNotVisible);
    };
  }, [isResolved, scrolledComponentId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!isVisible) {
        setIsVisible(true);
      }
    }, delay);
    return () => clearTimeout(timer);
  }, [isVisible]);

  return { isVisible };
};

export const useGetPath = () => {
  const location = useLocation();
  const routes = Object.values(Paths).map((path) => ({ path: path }));
  const value = matchRoutes(routes, location);
  return value ? value[0].route.path : undefined;
};

export const useHideNavigationBarByPath = () => {
  const path = useGetPath();
  if (!path) {
    return { hide: false };
  }
  return { hide: path === Paths.Project || path === Paths.Article };
};

export const createMarkup = (html?: string) => {
  if (!html) {
    return;
  }
  return {
    __html: html,
  };
};

export const useCountDown: (total: number, ms?: number) => [number, () => void, () => void, () => void] = (
  total: number,
  ms = 1000,
) => {
  const [counter, setCountDown] = useState(total);
  const [startCountDown, setStartCountDown] = useState(false);
  // Store the created interval
  const intervalId = useRef<any>();
  const start: () => void = () => setStartCountDown(true);
  const pause: () => void = () => setStartCountDown(false);
  const reset: () => void = () => {
    clearInterval(intervalId.current);
    setStartCountDown(false);
    setCountDown(total);
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      startCountDown && counter > 0 && setCountDown((counter) => counter - 1);
    }, ms);
    // Clear interval when count to zero
    if (counter === 0) clearInterval(intervalId.current);
    // Clear interval when unmount
    return () => clearInterval(intervalId.current);
  }, [startCountDown, counter, ms]);

  return [counter, start, pause, reset];
};

export const useHideHeader = () => {
  const [hide, setHide] = useState(false);
  const { window: p } = useDimensions();
  const { height } = p;

  const handleScroll = () => {
    if (window.scrollY < height + 500) {
      setHide(false);
    }
    if (!hide && window.scrollY > height) {
      setHide(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { hide };
};

export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  const update = useCallback(() => {
    setValue((value) => value + 1);
  }, [value]);
  return { update: value, updateFn: update }; // update state to force render
}

import { Style } from "../../../globalTypes";
import { Theme } from "@mui/material";
import { ScreenSize } from "../../../hooks/useDimensions/types";

export const useModalStyle = (theme: Theme, screenSize: ScreenSize): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";

  return {
    container: {
      position: "fixed",
      top: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: "100%",
      height: "100vh",
      zIndex: "1001",
      //backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    modal: {
      bottom: 0,
      width: isSmall ? "100%" : "98%",
      height: isSmall ? "100%" : "90%",
      borderTopRightRadius: isSmall ? 0 : 1,
      borderTopLeftRadius: isSmall ? 0 : 1,
      backgroundColor: theme.palette.background.paper,
      opacity: 1,
      zIndex: "1000",
      overflowY: "auto",
      padding: isSmall ? 24 : 32,
      paddingTop: 0,
    },
    header: {
      position: "fixed",
      paddingRight: isSmall ? 0 : 8,
      width: isSmall ? `calc(100vw - 48px)` : `calc(100vw - 80px)`,
      zIndex: 10,
    },
    btnContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 32,
    },
    btn: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: 0,
      color: theme.palette.primary.light,
    },
  };
};

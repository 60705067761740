import { Style } from "../../../../globalTypes";

export const useNavigationBarStyle = (isSmall: boolean): Style => {
  return {
    navbar: {
      position: "fixed",
      width: "100%",
      height: isSmall ? 100 : 120,
      display: "flex",
      bottom: 0,
      justifyContent: "center",
      alignItems: "flex-start",
      zIndex: "1005",
    },
    container: {
      display: "flex",
      padding: 10,
      paddingRight: 4,
      paddingLeft: 4,
      borderRadius: 8,
      justifyContent: "center",
      minWidth: "fit-content",
    },
    containerBackgroundFrom: { backgroundColor: "rgba(29, 27, 27, 0)", backdropFilter: "blur(2px)" },
    containerBackground: {
      backgroundColor: "rgba(29, 27, 27, 0.4)",
      backdropFilter: "blur(4px)",
    },
  };
};

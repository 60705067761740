import { useGlobal } from "../../../state/global/useGlobal";
import CustomTypography from "../customTypography/customTypography";
import React, { useCallback } from "react";
import { getAvailableLanguages } from "../../../config/config";
import { Language } from "../../../state/global/global.types";

const LanguageComponent = () => {
  const { language, setLanguage } = useGlobal();
  const availableLanguages = getAvailableLanguages();

  const onLanguageClick = useCallback(
    (newLanguage: Language) => {
      setLanguage({ language: newLanguage });
    },
    [language],
  );

  return (
    <>
      {availableLanguages.map((lang, index) => (
        <React.Fragment key={lang}>
          <CustomTypography
            fontWeight={language === lang ? "bold" : "light"}
            style={language !== lang ? { cursor: "pointer" } : undefined}
            onClick={() => onLanguageClick(lang)}
          >
            {lang.toUpperCase()}
          </CustomTypography>
          {index !== availableLanguages.length - 1 && (
            <CustomTypography style={{ marginLeft: 4, marginRight: 4 }} fontWeight="light">
              /
            </CustomTypography>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default LanguageComponent;

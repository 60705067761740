import { Exhibition } from "../types";
import { useCallback, useRef, useState } from "react";
import { IShowExhibition } from "./types";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

const exhibitionsToShowExhibition = (exhibitions: { [key: string]: Exhibition[] }): IShowExhibition[] => {
  const showEx = Object.keys(exhibitions)
    .map((year) => {
      return {
        id: year,
        show: false,
        exhibitions: exhibitions[year] ?? [],
      };
    })
    .reverse();
  return showEx.filter((se) => se.exhibitions.length !== 0);
};

const updateExhibitionsById = (exhibitions: IShowExhibition[], id: string) => {
  const objectIndex = _.findIndex(exhibitions, (e) => e.id === id);
  return exhibitions.map((exhibition, index) =>
    objectIndex >= index || id === exhibition.id
      ? {
          ...exhibition,
          show: true,
        }
      : exhibition,
  );
};

const useTimelineComponent = (exhibitions: { [key: string]: Exhibition[] }, endOfTimeline?: () => void) => {
  const [, setUpdate] = useState(0);
  const [exSelectedCategory, setExSelectedCategory] = useState<"personal" | "collective">("personal");
  const theme = useTheme();
  const exhibitionsRef = useRef<IShowExhibition[]>(exhibitionsToShowExhibition(exhibitions));
  const initialColor = "rgba(86, 86, 86, 0.1)";
  const fillColor = theme.palette.primary.light;

  const getUpdatedExhibitions = useCallback(
    (id: string, ex: IShowExhibition[]) => {
      if (endOfTimeline && Object.keys(exhibitions).length - 1 === parseInt(id)) {
        endOfTimeline();
      }
      return updateExhibitionsById(ex, id);
    },
    [exSelectedCategory],
  );

  const showYearCallback = useCallback(
    (id: string) => {
      setUpdate(Math.random);
      exhibitionsRef.current = getUpdatedExhibitions(id, exhibitionsRef.current);
    },
    [exSelectedCategory],
  );

  const onCategoryChange = useCallback(() => {
    if (exSelectedCategory === "collective") {
      setExSelectedCategory("personal");
      return;
    }
    setExSelectedCategory("collective");
  }, [exSelectedCategory]);

  return {
    selectedCategory: exSelectedCategory,
    exhibitions: exhibitionsRef.current,
    initialColor,
    fillColor,
    showYearCallback,
    onCategoryChange,
  };
};

export default useTimelineComponent;

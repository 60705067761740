import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { useCustomTheme } from "./themes/themes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { GlobalContextProvider } from "./state/global/global.context";
import { BrowserRouter } from "react-router-dom";
import { useGlobal } from "./state/global/useGlobal";
import { I18nextProvider } from "react-i18next";
import i18next from "./i18n/init";
import "../src/components/atoms/loadingSkeleton/skeleton.css";

const AppComponent = () => {
  useGlobal(); //** THIS HOOK MANAGES THE GLOBAL CONTEXT **//

  return (
    <I18nextProvider i18n={i18next}>
      <GlobalContextProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </GlobalContextProvider>
    </I18nextProvider>
  );
};

const MainComponent = () => {
  const theme = useCustomTheme();
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppComponent />
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<MainComponent />);

reportWebVitals();

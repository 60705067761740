import { Card } from "@mui/material";
import React, { CSSProperties, useMemo } from "react";
import { Article } from "../../../pages/diaryPage/types";
import CustomTypography from "../customTypography/customTypography";
import { LoadingSkeleton } from "../loadingSkeleton/LoadingSkeleton";
import "./style.css";
import { useArticleCard } from "./useArticleCard";
import { Variant } from "@mui/material/styles/createTypography";
import FocusedImage from "../focusedImage/FocusedImage";

export interface IArticleCard {
  article: Article;
  style?: CSSProperties;
  styleImg?: CSSProperties;
  variantTitle?: Variant;
  variantDate?: Variant;
}

const ArticleCard = (props: IArticleCard) => {
  const {
    article,
    loading,
    style,
    styleImg,
    styles,
    onReadClick,
    variantTitle = "body1",
    onLoadImage,
  } = useArticleCard(props);

  const Image = useMemo(() => {
    return (
      <div className={"img"}>
        <FocusedImage
          src={article.imageCover}
          alt={article.title}
          style={{ display: loading ? "none" : "block" }}
          x={article.x_thumbnail_position ? article.x_thumbnail_position : undefined}
          y={article.y_thumbnail_position ? article.y_thumbnail_position : undefined}
          onLoadImage={onLoadImage}
        />
      </div>
    );
  }, [loading]);

  return (
    <Card style={{ ...styles.card, ...style }}>
      <div style={{ ...styles.imgContainer, ...styleImg }} onClick={onReadClick}>
        {loading && <LoadingSkeleton width="100%" height="100%" />}
        {Image}
      </div>
      <div style={styles.cardContent}>
        <CustomTypography gutterBottom variant={variantTitle}>
          {article.title}
        </CustomTypography>
      </div>
    </Card>
  );
};

export default ArticleCard;

import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

interface ICustomDrawerHeader {
  handleDrawerClose: () => void;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const CustomDrawerHeader = (props: ICustomDrawerHeader) => {
  const { handleDrawerClose } = props;
  const theme = useTheme();

  return (
    <DrawerHeader>
      <div style={{ paddingTop: 8, paddingBottom: 8 }}>
        <IconButton
          size="small"
          sx={{
            color: theme.palette.primary.light,
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
          onClick={handleDrawerClose}
        >
          {theme.direction === "ltr" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
    </DrawerHeader>
  );
};

export default CustomDrawerHeader;

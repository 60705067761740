import React, { useCallback, useMemo } from "react";
import { Divider } from "@mui/material";
import CustomTypography from "../../atoms/customTypography/customTypography";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";
import { useFooterStyle } from "./styles";
import logo from "../../../assets/images/vb-logo.png";
import LanguageComponent from "../../atoms/language/Language";
import { useGlobal } from "../../../state/global/useGlobal";

type AvailableSocial = "instagram" | "facebook";

interface IFooter {
  padding?: boolean;
}

const Footer = (props: IFooter) => {
  const { padding = true } = props;
  const { data } = useGlobal();
  const { settings } = data;

  const theme = useTheme();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const styles = useFooterStyle(theme, screenSize);

  const Logo = useMemo(() => {
    return (
      <div style={styles.logoContainer}>
        <img src={logo} style={styles.logo} alt="logo" />
      </div>
    );
  }, []);

  const openSocial = useCallback((social: AvailableSocial) => {
    const urlSocial = (social: AvailableSocial): string | null => {
      if (!settings) {
        return null;
      }
      switch (social) {
        case "facebook":
          return settings[0] ? settings[0].link_facebook : null;
        case "instagram":
          return settings[0] ? settings[0].link_instagram : null;
        default:
          return null;
      }
    };

    const url = urlSocial(social);
    if (!url) {
      return;
    }
    window.open(url, "_blank", "noopener,noreferrer");
  }, []);

  return (
    <div id="footer" style={styles.container}>
      <Divider style={padding ? styles.divider : styles.dividerNoMargin} />
      <div style={padding ? styles.dataContainer : styles.dataContainerNoPadding}>
        {!isSmall && <div style={styles.leftContainer}>{Logo}</div>}
        <div style={styles.socialContainer}>
          <div style={styles.containerSocial}>
            <InstagramIcon
              style={{ ...styles.icon, marginLeft: isSmall ? undefined : 4 }}
              onClick={() => {
                openSocial("instagram");
              }}
            />
            <FacebookIcon
              style={{ ...styles.icon, marginLeft: isSmall ? undefined : 4 }}
              onClick={() => {
                openSocial("facebook");
              }}
            />
          </div>
          <div style={styles.languageContainer}>
            <LanguageComponent />
          </div>
        </div>
      </div>
      <Divider style={padding ? styles.divider : styles.dividerNoMargin} />
      <div style={styles.copyContainer}>
        <CustomTypography style={styles.copyTxt}>
          {isSmall ? (
            <>
              Copyright 2022 @valerioberruti.
              <br />
              All Rights Reserved
            </>
          ) : (
            "Copyright 2022 @valerioberruti. All Rights Reserved"
          )}
        </CustomTypography>
      </div>
    </div>
  );
};

export default Footer;

import { Style } from "../../../globalTypes";
import { useTheme } from "@mui/material";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";
import { useMemo } from "react";

export const useDrawerStyle = (): Style => {
  const theme = useTheme();
  const { screenSize } = useDimensions();

  const isSmall = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs";
  }, [screenSize]);

  return {
    listItem: { display: "flex", flexDirection: "column" },
    listItemButton: { display: "flex", justifyContent: "center" },
    imageContainer: { height: isSmall ? "11vh" : "10vh" },
    typo: { color: theme.palette.primary.light, fontSize: "0.8rem" },
  };
};

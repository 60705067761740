import { useGlobal } from "../../state/global/useGlobal";
import { useTheme } from "@mui/material/styles";
import { useContactPageStyle } from "./styles";
import { useTranslation } from "react-i18next";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { useCallback, useState } from "react";

export const useContactPage = () => {
  const { data } = useGlobal();
  const { works } = data;
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const styles = useContactPageStyle(theme, isSmall);
  const { t } = useTranslation();
  const [showContactLabel, setShowContactLabel] = useState(true);

  const hideContact = useCallback(() => {
    setShowContactLabel(false);
  }, []);

  return { styles, works, t, showContactLabel, hideContact };
};

import React, { useMemo } from "react";
import AnimatedPage from "../../components/templates/AnimatedPage";
import { Paths } from "../../navigation/types";
import { useWorksPage } from "./useWorksPage";
import { Grid } from "@mui/material";
import WorkCard from "../../components/atoms/workCard/WorkCard";
import Footer from "../../components/molecules/footer/Footer";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import ImageHeader from "../../components/atoms/image/Image";
import Logo from "../../components/atoms/Logo/Logo";

const WorksPage: React.FC = () => {
  const { loading, styles, works, overlay, t, onLoadImage } = useWorksPage();

  const Header = useMemo(() => {
    return (
      <div style={styles.containerHeader}>
        <Logo show={true} containerStyle={{ position: "absolute" }} />
        {loading && <LoadingSkeleton width="100%" height="100%" />}
        <ImageHeader
          src={"https://valerioberruti.com/media/work_images/13.nel-Suo-nome.jpg"}
          text={t("label.works").toUpperCase()}
          alt={t("label.altWorkImage")}
          x={0.3}
          y={0.45}
          overlayComponentStyle={styles.overlayComponentStyle}
          imageContainerStyle={styles.imageContainerStyle}
          onLoadImage={onLoadImage}
        />
      </div>
    );
  }, [loading, styles]);

  return (
    <AnimatedPage key={Paths.Works} style={styles.container}>
      <>
        {overlay && <div style={styles.modalBcg} />}
        {Header}
        <div>
          <Grid container spacing={6} style={styles.gridContainer} rowSpacing={{ xs: 4 }}>
            {works.map((work, index) => {
              return (
                <Grid key={index} item xs={12} md={4}>
                  <WorkCard work={work} style={{ width: "100%" }} />
                </Grid>
              );
            })}
          </Grid>
        </div>
        <Footer />
      </>
    </AnimatedPage>
  );
};

export default WorksPage;

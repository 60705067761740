import Footer from "../../components/molecules/footer/Footer";
import { Paths } from "../../navigation/types";
import AnimatedPage from "../../components/templates/AnimatedPage";
import Mail from "../../components/molecules/mail/Mail";
import CustomTypography from "../../components/atoms/customTypography/customTypography";
import React from "react";
import { useContactPage } from "./useContactPage";
import Logo from "../../components/atoms/Logo/Logo";

const ContactPage = () => {
  const { styles, showContactLabel, t, hideContact } = useContactPage();

  return (
    <AnimatedPage key={Paths.Contact} style={styles.container}>
      <>
        <Logo containerStyle={styles.logo} />
        <div style={styles.formContainer}>
          <div style={styles.componentContainer}>
            {showContactLabel && (
              <CustomTypography style={styles.contactText}>{t("label.contact").toUpperCase()}</CustomTypography>
            )}
            <Mail onSuccess={hideContact} />
          </div>
        </div>
        <Footer />
      </>
    </AnimatedPage>
  );
};

export default ContactPage;

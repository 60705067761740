import React, { useMemo } from "react";
import { Paths } from "../../navigation/types";
import AnimatedPage from "../../components/templates/AnimatedPage";
import useExhibitionsPage from "./useExhibitionsPage";
import TimelineComponent from "./Timeline/Timeline";
import Footer from "../../components/molecules/footer/Footer";
import Scene from "../../components/templates/scene/Scene";
import CustomTypography from "../../components/atoms/customTypography/customTypography";
import PlaceIcon from "@mui/icons-material/Place";
import Logo from "../../components/atoms/Logo/Logo";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import ImageHeader from "../../components/atoms/image/Image";

const ExhibitionsPage: React.FC = () => {
  const { exhibitionsByYear, loading, onLoadImage, img, overlayTxt, variant, styles, t } = useExhibitionsPage();

  const Header = useMemo(() => {
    return (
      <div style={styles.containerHeader}>
        <Logo show={true} containerStyle={{ position: "absolute" }} />
        {loading && <LoadingSkeleton width="100%" height="100%" />}
        <ImageHeader
          src={img}
          y={-0.6}
          text={overlayTxt.toUpperCase()}
          alt={t("label.altExImage")}
          onLoadImage={onLoadImage}
          overlayComponentStyle={styles.overlayComponentStyle}
          imageContainerStyle={styles.imageContainerStyle}
          textStyle={styles.textStyle}
        />
      </div>
    );
  }, [loading, styles]);

  const ExhibitionHistory = useMemo(() => {
    if (!exhibitionsByYear) {
      return <></>;
    }

    return <TimelineComponent exhibitions={exhibitionsByYear} />;
  }, [exhibitionsByYear]);

  const Alba = () => {
    return (
      <>
        <div style={styles.sceneContainer}>
          <Scene />
        </div>
        <div style={styles.titleContainer}>
          <CustomTypography variant={variant} style={styles.titleStyle}>
            "Alba" /
          </CustomTypography>
          <PlaceIcon style={styles.icon} />
          <CustomTypography variant={variant} style={styles.titleStyle}>
            P.za Michele Ferrero
          </CustomTypography>
        </div>
      </>
    );
  };

  return (
    <AnimatedPage key={Paths.Exhibitions} style={styles.container}>
      <div id="exhibitions-container">
        {Header}
        {ExhibitionHistory}
        <Alba />
        <div style={styles.border} />
        <Footer />
      </div>
    </AnimatedPage>
  );
};

export default ExhibitionsPage;

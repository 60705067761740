import React, { useMemo } from "react";
import TimelineObserver from "../../../components/atoms/timelineObserver/TimelineObserver";
import { IShowExhibition, ITimelineComponent, ITimelineProps } from "./types";
import TimelineStep from "./TimelineStep/TimelineStep";
import "./style.css";
import useTimelineComponent from "./useTimelineComponent";
import LegendComponent from "../../../components/atoms/legendComponent/legendComponent";

const Timeline = (props: ITimelineProps) => {
  const { exhibitions, selectedCategory, ...otherProps } = props;

  const isCollectiveSelected = selectedCategory === "collective";
  const filteredExhibitions = exhibitions.map((exObj) => {
    return { ...exObj, exhibitions: exObj.exhibitions.filter((e) => e.is_collective === isCollectiveSelected) };
  });

  return (
    <div className="wrapper">
      {filteredExhibitions.map((value: IShowExhibition, index: number) => (
        <TimelineStep
          {...value}
          {...otherProps}
          filteredExhibitions={filteredExhibitions}
          key={value.exhibitions[0] ? value.exhibitions[0].year : index}
          index={index}
        />
      ))}
    </div>
  );
};

const TimelineComponent = (props: ITimelineComponent) => {
  const { selectedCategory, exhibitions, fillColor, initialColor, showYearCallback, onCategoryChange } =
    useTimelineComponent(props.exhibitions, props.endOfTimeline);

  const TimelineContainer = useMemo(() => {
    if (!exhibitions[0].exhibitions[0]) {
      return;
    }
    return (
      <TimelineObserver
        initialColor={initialColor}
        fillColor={fillColor}
        handleObserve={(setObserver) => (
          <Timeline
            className="timeline"
            exhibitions={exhibitions}
            callback={showYearCallback}
            setObserver={setObserver}
            selectedCategory={selectedCategory}
          />
        )}
      />
    );
  }, [exhibitions, selectedCategory]);

  return (
    <div className="timelineContainer">
      <div className="timelineObserverContainer">
        <div className="legend">
          <LegendComponent selectedCategory={selectedCategory} onCategoryChange={onCategoryChange} />
        </div>
        {TimelineContainer}
      </div>
    </div>
  );
};

export default TimelineComponent;

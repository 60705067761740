import { useLayoutEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useGlobal } from "../../../state/global/useGlobal";

interface IScrollToTop {
  showRoutes: boolean;
  children: JSX.Element;
}

const ScrollToTop = (props: IScrollToTop) => {
  const { children, showRoutes } = props;
  const location = useLocation();
  const { hideLandingPage } = useGlobal();

  const notForceScrolling = useMemo(() => {
    // for handling modal open/close
    return location.pathname.split("/").length === 3;
  }, [location.pathname]);

  useLayoutEffect(() => {
    if (notForceScrolling) {
      return;
    }
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname, hideLandingPage, showRoutes, notForceScrolling]);
  return children;
};

export default ScrollToTop;

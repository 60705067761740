import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigationBarStyle } from "./styles";
import { useNavigation } from "../../../../navigation/useNavigation";
import { getNavbarButtons, NavButton } from "../../../../config/config";
import { Paths } from "../../../../navigation/types";
import { useLocation } from "react-router-dom";

export const useNavigationBar = () => {
  const buttons = getNavbarButtons();
  const { navigate } = useNavigation();
  const [open, setOpen] = useState<boolean>(false);
  const styles = useNavigationBarStyle(open);
  const location = useLocation();
  const { matches: isLandscape } = window.matchMedia("(orientation: landscape)");

  const menuVariants = {
    opened: {
      top: 0,
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: 0,
      },
    },
    closed: {
      top: "-100vh",
      opacity: 0,
      transition: {
        duration: 0.3,
        delay: 0,
      },
    },
  };

  useEffect(() => {
    const ctr = document.getElementById("container");
    if (ctr && ctr.parentElement) {
      if (isLandscape) {
        ctr.parentElement.style.height = "undefined";
        return;
      }
      ctr.parentElement.style.height = "100vh";
    }
  }, [isLandscape]);

  const styleBar = useMemo(() => {
    return open
      ? {
          justifyContent: "flex-end",
          backdropFilter: "blur(2px)",
          backgroundColor: "rgba(29, 27, 27, 0.05)",
        }
      : { justifyContent: "flex-end", backgroundColor: "transparent" };
  }, [open]);

  const animate = useMemo(() => {
    return open ? "opened" : "closed";
  }, [open]);

  const handleClickMenu = useCallback(() => {
    document.body.style.overflow = !open ? "hidden" : "visible";
    setOpen(!open);
  }, [open]);

  const selectedIndex = useMemo(() => {
    if (location.pathname.includes(Paths.Home)) {
      return 0;
    }
    if (location.pathname.includes(Paths.Works)) {
      return 1;
    }
    if (location.pathname.includes(Paths.Diary)) {
      return 3;
    }
    switch (location.pathname) {
      case Paths.Exhibitions:
        return 2;
      case Paths.About:
        return 4;
      case Paths.Contact:
        return 5;
      default:
        return null;
    }
  }, [location]);

  const showButton = useMemo(() => {
    if (!location.pathname.split("/")[2]) {
      return true;
    }
    return location.pathname.split("/")[2] === "";
  }, [location.pathname]);

  const handleClickRoute = useCallback(
    (button: NavButton, selected?: boolean) => {
      if (!selected) {
        document.body.style.overflow = !open ? "hidden" : "visible";
        setOpen(!open);
        navigate(button.path);
      }
    },
    [navigate, open],
  );

  return {
    buttons,
    open,
    showButton,
    selectedIndex,
    styles,
    styleBar,
    animate,
    menuVariants,
    handleClickMenu,
    handleClickRoute,
  };
};

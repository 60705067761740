import React, { useMemo } from "react";
import { useNavigationButtonStyle } from "./styles";
import { useTheme } from "@mui/material/styles";

export interface NavigationButtonProps {
  index: number;
  title: string;
  onClick?: () => void;
  selected?: boolean;
}

const D = "M0 0 l200 0";

const NavigationButton: React.FC<NavigationButtonProps> = ({ index, title, onClick, selected = false }) => {
  const theme = useTheme();
  const styles = useNavigationButtonStyle(theme);

  const styleBox = useMemo(() => {
    if (index === 0) {
      return { marginLeft: 16, ...styles.btnContainer };
    }
    return { ...styles.btnContainer };
  }, [index]);

  const SelectedButton = useMemo(() => {
    return (
      <div style={{ ...styleBox, ...styles.btnContainerSelected }} key={title}>
        {title}
        <div style={styles.lineContainer} className="line">
          <svg style={styles.svg}>
            <path d={D} style={styles.path} />
          </svg>
        </div>
      </div>
    );
  }, []);

  const Button = useMemo(() => {
    return (
      <div style={{ ...styleBox, ...styles.btnContainerNoSelected }} onClick={onClick} key={title}>
        <div style={styles.cursor}>{title}</div>
      </div>
    );
  }, []);

  return selected ? SelectedButton : Button;
};

export default NavigationButton;

import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Modal from "../../components/templates/modal/Modal";
import { useProjectPage } from "./useProjectPage";
import PlaceIcon from "@mui/icons-material/Place";
import CustomTypography from "../../components/atoms/customTypography/customTypography";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import Footer from "../../components/molecules/footer/Footer";
import { createMarkup } from "../../hooks/useUtils/useUtils";
import CustomImages from "../../components/atoms/customImages/CustomImages";
import { ProjectPageParams } from "./types";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Image } from "../../schemas";

const ProjectPage: React.FC = () => {
  const { projectId } = useParams<ProjectPageParams>();
  const { project, loading, isSmall, styles, variantTitle, variantPlace, onModalClose, setIsLoaded } =
    useProjectPage(projectId);

  if (!project) {
    return <></>;
  }

  const content = createMarkup(project.content);

  const ImagesComponent = useMemo(() => {
    const imageCover: Image = {
      id: 0,
      image: project.image_thumbnail,
      image_alt: "image_cover",
      x_position: project.x_thumbnail,
      y_position: project.y_thumbnail,
    };
    return (
      <CustomImages
        images={project.images && project.images.length ? project.images : [imageCover]}
        style={styles.image}
        loading={loading}
        onLoadImage={setIsLoaded}
      />
    );
  }, [loading, project]);

  const HeaderProjectPage = () => {
    return (
      <div style={styles.placeContainer}>
        <CustomTypography variant={variantPlace} style={styles.headerText}>
          {isSmall && <FiberManualRecordIcon style={styles.icon} />}
          {project.year}
        </CustomTypography>
        {project.place &&
          (isSmall ? (
            <div style={styles.iconContainerSmall}>
              <PlaceIcon style={styles.icon} />
              <CustomTypography variant={variantPlace} style={styles.headerTextSmall}>
                {project.place}
              </CustomTypography>
            </div>
          ) : (
            <>
              <CustomTypography variant={variantPlace}>&nbsp; /</CustomTypography>
              <PlaceIcon style={styles.icon} />
              <CustomTypography variant={variantPlace} style={styles.headerText}>
                {project.place}
              </CustomTypography>
            </>
          ))}
      </div>
    );
  };

  const PageComponent: React.FC = () => {
    return (
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <HeaderProjectPage />
          <CustomTypography variant={variantTitle} style={styles.title}>
            {project.title}
          </CustomTypography>
        </div>
        <div style={styles.imgContainer}>
          {loading && <LoadingSkeleton width="100%" height="60vh" />}
          {ImagesComponent}
        </div>
        <CustomTypography component="div" dangerouslySetInnerHTML={content} variant="h6" style={styles.description} />
        {project.video && (
          <div style={styles.videoContainer}>
            <video style={styles.video} controls>
              <source src={project.video} type="video/mp4" />
            </video>
          </div>
        )}
        {project.embedded_link_video && (
          <div style={styles.videoContainer}>
            <iframe
              style={styles.video}
              src={project.embedded_link_video}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        )}
        <Footer padding={false} />
      </div>
    );
  };

  return <Modal Component={PageComponent} onClose={onModalClose} />;
};

export default ProjectPage;

import { Actions, GlobalState } from "./global.types";
import {
  SET_LANDING_PAGE_IMAGES,
  SET_FINISHED_SCROLLING,
  SET_IS_DATA_LOADED,
  SET_LANGUAGE,
  SET_MODAL,
  SET_THEME,
  SET_HIDE_LANDING_PAGE,
} from "./global.constants";

export const initializeState = (): GlobalState => {
  return {
    data: {
      articles: [],
      exhibitions: [],
      settings: [],
      works: [],
    },
    isDataLoaded: false,
    hideLandingPage: false,
    finishedScrolling: false,
    landingPageImages: [],
    modal: {
      open: false,
    },
    server_url: process.env.REACT_APP_SERVER_URL,
    theme: "light",
    version: process.env.REACT_APP_VERSION,
  };
};

export const GlobalReducer = (state: GlobalState, action: Actions): GlobalState => {
  switch (action.type) {
    case SET_LANDING_PAGE_IMAGES:
      return {
        ...state,
        ...action.payload,
      };
    case SET_IS_DATA_LOADED:
      return {
        ...state,
        isDataLoaded: true,
        ...action.payload,
      };
    case SET_FINISHED_SCROLLING:
      return {
        ...state,
        finishedScrolling: true,
      };
    case SET_HIDE_LANDING_PAGE:
      return {
        ...state,
        hideLandingPage: true,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_THEME:
      return {
        ...state,
        ...action.payload,
      };
    case SET_MODAL:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default GlobalReducer;

import { Style } from "../../../globalTypes";
import { Theme } from "@mui/material";

export const useLegendComponentStyle = (theme: Theme, isSmall: boolean): Style => {
  return {
    container: { display: "flex", justifyContent: "center", margin: isSmall ? undefined : 32 },
    buttonC: {
      borderRadius: 2,
      backgroundColor: theme.palette.primary.light,
      textTransform: "none",
    },
    buttonO: {
      borderRadius: 2,
      color: theme.palette.primary.light,
      textTransform: "none",
    },
  };
};

import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import AlbaPageHtmlComponent from "./AlbaPageHtmlComponent";
import { useTimeout } from "../../hooks/useTimeout";
import LandingPage from "../landingPage/LandingPage";

const AlbaPage = () => {
  const [visible, setVisible] = useState(false);

  const show = () => setVisible(true);

  useTimeout(show, 500);

  useEffect(() => {
    document.documentElement.className = "has-js has-smooth-scroll has-dom-loaded has-dom-animated";
    document.body.className =
      "home page app-data index-data singular-data page-data page-2-data page-home-data front-page-data";

    return () => {
      document.documentElement.className = "";
      document.body.className = "";
    };
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript" src="js/app.js" id="sage/app.js-js"></script>
        <script type="text/javascript" src="js/prova.js" id="gtm-jq-ajax-listen" />
        <script type="text/javascript" src="js/prova1.js" />
        <link rel="stylesheet" id="sage/main.css-css" href="css/main.css" type="text/css" media="all" />
        <link rel="stylesheet" id="wp-block-library-css" href="css/style.min.css" type="text/css" media="all" />
      </Helmet>
      {!visible && <LandingPage showModel={false} />}
      <AlbaPageHtmlComponent visible={visible} />
    </>
  );
};

export default AlbaPage;

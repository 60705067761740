import V1NavigationBar from "./v1/V1";
import React from "react";
import { NavButton } from "../../../../../config/config";
import V2NavigationBar from "./v2/V2";

interface IButtons {
  buttons: NavButton[];
  selectedIndex: number | null;
  handleClick: (button: NavButton, selected?: boolean | undefined) => void;
  variant?: boolean;
}

const Buttons = (props: IButtons) => {
  const { buttons, selectedIndex, handleClick, variant } = props;
  return (
    <>
      {buttons.map((button, index) => {
        const selected = index === selectedIndex;
        return (
          <React.Fragment key={button.name.toString()}>
            {variant ? (
              <V1NavigationBar selected={selected} index={index} button={button} onButtonClick={handleClick} />
            ) : (
              <V2NavigationBar selected={selected} index={index} button={button} onButtonClick={handleClick} />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Buttons;

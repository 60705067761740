import { useHomePageStyles } from "./styles";
import { useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGlobal } from "../../state/global/useGlobal";
import { useTranslation } from "react-i18next";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import _ from "lodash";
import { ArticleSchema } from "../../schemas";
import { Article } from "../diaryPage/types";

const useShowLogo = () => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState<boolean>(true);

  const set = useCallback(() => {
    setShow(!show);
  }, [show]);

  const scrollHandler = useCallback(() => {
    if (!contentRef.current) {
      return;
    }
    if (
      (contentRef.current.getBoundingClientRect().top > 0 && show) ||
      (contentRef.current.getBoundingClientRect().top < 0 && !show)
    ) {
      return;
    }
    set();
  }, [show]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler, true);
    return () => {
      window.removeEventListener("scroll", scrollHandler, true);
    };
  }, [show]);

  return {
    contentRef,
    show: show,
  };
};

export const useHomePage = () => {
  const { data, language, modal } = useGlobal();
  const { settings, articles } = data;
  const { home_image } = settings[0];
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { contentRef, show } = useShowLogo();

  const styles = useHomePageStyles(theme, isSmall, show);

  const getPropertyByLanguage = useCallback(
    (article: ArticleSchema, property: string) => {
      const prop = property + language;
      return article[prop];
    },
    [language],
  );

  const sortedArticles = useMemo((): Article[] => {
    _.unset(
      articles
        .map((a) => ({ ...a, timestamp: new Date(a.publication_at).getTime() }))
        .sort((a, b) => a.timestamp - b.timestamp),
      "timestamp",
    );
    return articles.slice(0, 3).map((article): Article => {
      return {
        id: article.id,
        title: getPropertyByLanguage(article, "title_"),
        place: article.place,
        data: article.publication_at,
        content: getPropertyByLanguage(article, "content_"),
        imageCover: article.image_thumbnail,
        x_thumbnail_position: article.x_thumbnail_position,
        y_thumbnail_position: article.y_thumbnail_position,
      };
    });
  }, [articles, language]);

  const overlay = useMemo(() => {
    return modal.open;
  }, [modal]);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  return {
    contentRef,
    home_image,
    loading,
    overlay,
    sortedArticles,
    show,
    styles,
    t,
    onLoadImage,
  };
};

import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { useDiaryPageStyle } from "./styles";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useGlobal } from "../../state/global/useGlobal";
import { Article } from "./types";
import { ArticleSchema } from "../../schemas";
import { useHideHeader } from "../../hooks/useUtils/useUtils";

const useDiaryPage = () => {
  const { data, modal, language } = useGlobal();
  const { articles } = data;
  const { t } = useTranslation();
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const { hide } = useHideHeader();

  const styles = useDiaryPageStyle(theme, screenSize, hide);
  const [loading, setLoading] = useState(true);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  const getPropertyByLanguage = useCallback(
    (article: ArticleSchema, property: string) => {
      const prop = property + language;
      return article[prop];
    },
    [language],
  );

  const articlesData = useMemo((): Article[] | undefined => {
    if (!articles) {
      return;
    }

    return articles.map((article): Article => {
      return {
        id: article.id,
        title: getPropertyByLanguage(article, "title_"),
        place: article.place,
        data: article.publication_at,
        content: getPropertyByLanguage(article, "content_"),
        imageCover: article.image_thumbnail,
        x_thumbnail_position: article.x_thumbnail_position,
        y_thumbnail_position: article.y_thumbnail_position,
      };
    });
  }, [articles, language]);

  const overlay = useMemo(() => {
    return modal.open;
  }, [modal]);

  return {
    articles: articlesData,
    loading,
    img: "https://valerioberruti.com/media/work_images/1-1.jpg",
    overlayTxt: t("label.diary"),
    overlay,
    styles,
    t,
    onLoadImage,
  };
};

export default useDiaryPage;

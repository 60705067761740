import { IArticleCard } from "./ArticleCard";
import { useGlobal } from "../../../state/global/useGlobal";
import { useNavigation } from "../../../navigation/useNavigation";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";
import { useArticleCardStyle } from "./style";
import { useTranslation } from "react-i18next";
import { Paths } from "../../../navigation/types";
import moment from "moment";
import "moment/locale/it";
import { useGetPath } from "../../../hooks/useUtils/useUtils";

export const useArticleCard = (props: IArticleCard) => {
  const { article, style, styleImg, variantTitle, variantDate } = props;
  const { id } = article;
  const { setModal, language } = useGlobal();
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const styles = useArticleCardStyle(theme, screenSize);
  const { t } = useTranslation();
  const path = useGetPath();
  const basePath = path ? path.split(":")[0] : "";

  const articleData = useMemo((): string | undefined => {
    if (!language) {
      return;
    }
    moment.locale(language);
    return moment(article.data).locale("de").format("LL");
  }, [article, language]);

  const onReadClick = useCallback(() => {
    if (loading) {
      return;
    }
    setModal({ modal: { open: true, path: Paths.Diary } });
    navigate(basePath + "/" + id);
  }, [basePath, navigate, setModal, loading, id]);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  return {
    article,
    articleData,
    loading,
    style,
    styleImg,
    styles,
    t,
    variantTitle,
    variantDate,
    onReadClick,
    onLoadImage,
  };
};

import { IModal } from "./Modal";
import { useGlobal } from "../../../state/global/useGlobal";
import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";
import { useModalStyle } from "./styles";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useContainerDimensions from "../../../hooks/useContainerDimensions/useContainerDimensions";
import { useTransition } from "react-spring";

const useModal = (props: IModal) => {
  const { Component, onClose } = props;
  const { modal, language } = useGlobal();
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const styles = useModalStyle(theme, screenSize);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const modalHeader = useRef<HTMLDivElement | null>(null);
  const modalDimensions = useContainerDimensions(modalRef);
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const stopResizing = useRef<boolean>(false);

  useEffect(() => {
    if (!modal.open && modalRef.current) {
      modalRef.current.style.width = "100%";
      modalRef.current.style.height = "100%";
    }
  }, [modal, modalRef]);

  useEffect(() => {
    if (stopResizing.current) {
      stopResizing.current = false;
    }
  }, [language, stopResizing]);

  useEffect(() => {
    if (isSmall) {
      return;
    }
    if (modalDimensions.width && modalHeader.current) {
      modalHeader.current.style.width = modalDimensions.width - 40 + "px";
    }
  }, [modalHeader, modalDimensions]);

  const handleScrollModal = useCallback(() => {
    if (isSmall) {
      return;
    }

    function expanding() {
      if (modalRef.current) {
        if (stopResizing.current) {
          return;
        }

        const scrollAndSpeedW = isSmall ? modalRef.current?.scrollTop / 30 : modalRef.current?.scrollTop / 50;
        const scrollAndSpeedH = isSmall ? modalRef.current?.scrollTop / 10 : modalRef.current?.scrollTop / 10;

        const nextW = Math.min(Math.max((isSmall ? 96 : 98) + scrollAndSpeedW, isSmall ? 96 : 98), 100) + "%";
        const nextH = Math.min(Math.max((isSmall ? 96 : 90) + scrollAndSpeedH, isSmall ? 96 : 90), 100) + "%";

        modalRef.current.style.width = nextW;
        modalRef.current.style.height = nextH;

        if (nextW === "100%" && nextH === "100%") {
          stopResizing.current = true;
        }
      }
    }

    if (modal.open) {
      requestAnimationFrame(expanding);
    }
  }, [modal, modalRef]);

  const transitions = useTransition(true, {
    from: { opacity: 0, transform: "translateY(140px)" },
    enter: { opacity: 1, transform: "translateY(0px)" },
    leave: { opacity: 0, transform: "translateY(-40px)" },
  });

  const modalStyle = useMemo(() => {
    if (stopResizing.current) {
      //return { ...styles.modal, height: "100%", width: "100%", borderRadius: 0 };
    }
    return modal.open ? styles.modal : { ...styles.modal, height: "100%", width: "100%", borderRadius: 0 };
  }, [modal, styles]);

  return {
    Component,
    modalHeader,
    modalRef,
    modalStyle,
    styles,
    transitions,
    handleScrollModal,
    onClose,
  };
};

export default useModal;

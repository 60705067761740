import { IWorkCard } from "./WorkCard";
import { useGlobal } from "../../../state/global/useGlobal";
import { useNavigation } from "../../../navigation/useNavigation";
import { useCallback, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";
import { useWorkCardStyle } from "./style";
import { useTranslation } from "react-i18next";
import { Paths } from "../../../navigation/types";
import "moment/locale/it";
import { useGetPath } from "../../../hooks/useUtils/useUtils";

export const useWorkCard = (props: IWorkCard) => {
  const { work, style } = props;
  const { id } = work;
  const { setModal, language } = useGlobal();
  const { navigate } = useNavigation();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const styles = useWorkCardStyle(theme, screenSize);
  const { t } = useTranslation();
  const path = useGetPath();
  const basePath = path ? path.split(":")[0] : "";

  const onWorkClick = useCallback(() => {
    if (loading) {
      return;
    }
    setModal({ modal: { open: true, path: Paths.Works } });
    navigate(basePath + "/" + id);
  }, [basePath, navigate, setModal, loading, id]);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  const title = useMemo(() => {
    const property = "title_" + language;
    return work[property];
  }, [props, language]);

  return {
    work,
    loading,
    style,
    styles,
    t,
    title,
    onWorkClick,
    onLoadImage,
  };
};

import { Typography, TypographyProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as React from "react";

interface ICustomTypography extends TypographyProps {
  fontWeight?: "light" | "bold" | "bolder";
  component?: "span" | "div";
}

const CustomTypography = (props: ICustomTypography) => {
  const { fontWeight = "400", component, style, ...otherProps } = props;
  const theme = useTheme();
  const defaultColor = theme.palette.primary.light;

  // const family = useMemo(() => {
  //   switch (fontFamily) {
  //     case "light":
  //       return "NeueMachinaLight";
  //     case "bold":
  //       return "NeueMachinaBold";
  //     default:
  //       return "NeueMachina";
  //   }
  // }, [fontFamily]);

  return (
    <Typography
      {...otherProps}
      {...props}
      fontWeight={fontWeight}
      style={{ color: defaultColor, ...style }}
      component={component ? component : "div"}
    />
  );
};

export default CustomTypography;

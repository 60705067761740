import { lightTheme as light } from "./lightTheme";
import { useGlobal } from "../state/global/useGlobal";
import { Theme } from "@mui/material";

export const useCustomTheme = (): Partial<Theme> | ((outerTheme: Theme) => Theme) => {
  const { theme } = useGlobal();
  switch (theme) {
    case "light":
      return light;
    default:
      return light;
  }
};

export enum Paths {
  Home = "/home",
  Works = "/archive",
  Exhibitions = "/exhibitions",
  Diary = "/diary",
  About = "/about",
  Project = "/archive/:projectId",
  ArticleHome = "/home/:articleId",
  Article = "/diary/:articleId",
  Contact = "/contact",
  PrivateWorks = "/works",
  Alba = "/alba",
}

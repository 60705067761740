import { Suspense } from "react";
import * as THREE from "three";
import { useLoader, Canvas } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { DDSLoader } from "three-stdlib";
import { OrbitControls } from "@react-three/drei";

THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());

const Object = () => {
  const materials = useLoader(MTLLoader, "alba.mtl");
  const obj = useLoader(OBJLoader, "alba.obj", (loader: any) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  return <primitive object={obj} scale={6.5} />;
};

const Scene = () => {
  return (
    <Canvas camera={{ position: [15, 6, -20], fov: 9 }} dpr={[1, 2]}>
      <Suspense fallback={null}>
        <color attach="background" args={["rgb(244, 240, 240)"]} />
        <pointLight position={[50, 50, 50]} intensity={0.2} />
        <hemisphereLight color="#ffffff" groundColor="#b9b9b9" position={[-7, 35, 13]} intensity={1.75} />
        <Object />
        <OrbitControls enableZoom={false} />
      </Suspense>
    </Canvas>
  );
};

export default Scene;

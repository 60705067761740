import React from "react";
import { motion, Transition, SVGMotionProps } from "framer-motion";

interface MButtonProps extends SVGMotionProps<any> {
  isOpen?: boolean;
  color?: string;
  strokeWidth?: string | number;
  transition?: Transition;
  lineProps?: any;
}

const MButton = (buttonProps: MButtonProps) => {
  const {
    isOpen = false,
    width = 24,
    height = 24,
    strokeWidth = 1,
    color = "#000",
    transition = undefined,
    lineProps = null,
    ...props
  } = buttonProps;
  const variant = isOpen ? "opened" : "closed";
  const top = {
    closed: {
      rotate: 0,
      translateY: 0,
    },

    opened: {
      rotate: 45,
      translateY: 2,
    },
  };
  const center = {
    closed: {
      opacity: 1,
    },
    opened: {
      opacity: 0,
    },
  };
  const bottom = {
    closed: {
      rotate: 0,
      translateY: 0,
    },
    opened: {
      rotate: -45,
      translateY: -2,
    },
  };
  const line = {
    stroke: color,
    strokeWidth: strokeWidth as number,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition,
    ...lineProps,
  };
  const unitHeight = 4;
  const unitWidth = (unitHeight * (width as number)) / (height as number);

  return (
    <motion.svg
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      style={{ cursor: "pointer" }}
      {...props}
      whileHover={{ scale: 1.1 }}
      transition={{ type: "spring", stiffness: 100, damping: 20 }}
    >
      <motion.line x1="0" x2={unitWidth} y1="0" y2="0" variants={top} {...line} />
      <motion.line x1="0" x2={unitWidth} y1="2" y2="2" variants={center} {...line} />
      <motion.line x1="0" x2={unitWidth} y1="4" y2="4" variants={bottom} {...line} />
    </motion.svg>
  );
};

export default MButton;

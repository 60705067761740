import { Style } from "../../../globalTypes";
import { ScreenSize } from "../../../hooks/useDimensions/types";

export const useImageStyle = (screenSize: ScreenSize): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";

  return {
    background: {
      height: isSmall ? "82vh" : "80vh",
      width: "100%",
      top: 0,
      left: 0,
      position: "absolute",
      backgroundColor: "rgba(255, 255, 255,0.32)",
      alignItems: "center",
      zIndex: 10,
      display: "flex",
      flexDirection: "column",
      fontSize: "4rem",
    },
    container: { flex: 1, display: "flex", alignItems: "flex-end" },
    imgContainer: {
      position: "fixed",
      height: isSmall ? "82vh" : "80vh",
      width: "100%",
    },
    img: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
    },
    arrowDown: {
      height: "48px",
      width: "48px",
      marginBottom: 8,
      color: "#333333",
    },
    text: {
      letterSpacing: isSmall ? "3.8px" : "4.5px",
      fontSize: isSmall ? "3.75rem" : "5rem",
      fontWeight: "450",
      color: "#333333",
    },
  };
};

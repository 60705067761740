import React, { useEffect, useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import { MainRoutes, PrivateRoutes } from "./navigation/MainRoutes";
import { useGlobal } from "./state/global/useGlobal";
import LandingPage from "./pages/landingPage/LandingPage";
import { useLanguage, useLoadingData, usePageTitle } from "./hooks/useUtils/useUtils";
import ScrollToTop from "./components/atoms/scrollToTop/ScrollToTop";
import ErrorPage from "./pages/errorPage/ErrorPage";
import { useDimensions } from "./hooks/useDimensions/useDimensions";
import MobileNavigationBar from "./components/molecules/navigationBar/navigationBarMobile/NavigationBar";
import WebNavigationBar from "./components/molecules/navigationBar/navigationBarWeb/NavigationBar";
import { useLocation } from "react-router-dom";

const App = () => {
  useLanguage(); //** THIS HOOK MANAGES THE LANGUAGE **//
  const { error } = useLoadingData(); //** THIS HOOK MANAGES ALL DATA LOADING **//
  usePageTitle(); //** THIS HOOK MANAGES TABS TITLE **//
  const { modal, isDataLoaded, finishedScrolling, hideLandingPage } = useGlobal();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const location = useLocation();

  const isPrivateRoute = useMemo(() => {
    return location.pathname.includes("works") || location.pathname.includes("/alba");
  }, [location]);

  const showModel = useMemo(() => {
    const showed = localStorage.getItem("animation_view");
    return showed !== "true";
  }, []);

  const showRoutes = useMemo(() => {
    if (showModel) {
      return isDataLoaded && finishedScrolling && hideLandingPage;
    }
    return isDataLoaded;
  }, [showModel, isDataLoaded, finishedScrolling, hideLandingPage]);

  useEffect(() => {
    if (!showModel) {
      return;
    }
    if (finishedScrolling) {
      localStorage.setItem("animation_view", "true");
    }
  }, [finishedScrolling]);

  return (
    <>
      <AnimatePresence exitBeforeEnter key="key">
        {showRoutes && isSmall && !isPrivateRoute && <MobileNavigationBar key="navbar" />}
        {error ? (
          <ErrorPage />
        ) : (
          <ScrollToTop showRoutes={showRoutes}>
            {isPrivateRoute ? (
              <PrivateRoutes />
            ) : showRoutes ? (
              <MainRoutes modal={modal} />
            ) : (
              <LandingPage showModel={showModel} />
            )}
          </ScrollToTop>
        )}
      </AnimatePresence>
      {showRoutes && !isSmall && !isPrivateRoute && <WebNavigationBar key="navbar" />}
    </>
  );
};

export default App;

import { styled } from "@mui/material/styles";
import { useDimensions } from "../../../../hooks/useDimensions/useDimensions";
import { useMemo } from "react";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => {
  const { screenSize } = useDimensions();

  const isSmall = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs";
  }, [screenSize]);

  const drawerWidth = useMemo(() => {
    return isSmall ? 180 : 240;
  }, [isSmall]);

  return {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  };
});

export default Main;

import React, { useMemo } from "react";
import { animated, useTransition } from "react-spring";
import logo from "../../../assets/images/vb-logo.png";
import { useLogoStyle } from "./style";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";

interface ILogo {
  show?: boolean;
  containerStyle?: React.CSSProperties;
  imgStyle?: React.CSSProperties;
}

const Logo = (props: ILogo) => {
  const { show = true, containerStyle, imgStyle } = props;
  const { screenSize } = useDimensions();
  const styles = useLogoStyle(screenSize);
  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return useMemo(() => {
    return transitions((style, show) => (
      <>
        {show && (
          <animated.div style={{ ...style, ...styles.logoContainer, ...containerStyle }}>
            <img src={logo} style={{ ...styles.logo, ...imgStyle }} alt="logo" />
          </animated.div>
        )}
      </>
    ));
  }, [show]);
};

export default Logo;

import React from "react";
import { animated } from "react-spring";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useModal from "./useModal";

export interface IModal {
  onClose?: () => void;
  Component: React.ElementType;
}

const Modal: React.FC<IModal> = (props) => {
  const { Component, modalRef, modalHeader, modalStyle, styles, transitions, handleScrollModal, onClose } =
    useModal(props);

  const ModalContent = () => {
    const ModalHeader = () => {
      return (
        <div style={styles.header} ref={modalHeader}>
          {onClose && (
            <div style={styles.btnContainer}>
              <IconButton onClick={onClose} disableRipple style={styles.btn}>
                <CloseIcon />
              </IconButton>
            </div>
          )}
        </div>
      );
    };

    return (
      <div style={modalStyle} ref={modalRef} onScroll={handleScrollModal}>
        <ModalHeader />
        <Component />
      </div>
    );
  };

  return transitions((style) => (
    <animated.div style={{ ...style, ...styles.container }}>
      <ModalContent />
    </animated.div>
  ));
};

export default Modal;

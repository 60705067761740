import React from "react";
import { useNavigationBar } from "./useNavigationBar";
import { animated, useTransition, config } from "react-spring";
import "./style.css";
import Buttons from "./variant/Buttons";

const WebNavigationBar: React.FC = () => {
  const { buttons, first, selectedIndex, show, styles, transitions, handleClick } = useNavigationBar();

  const Container: React.FC = () => {
    const transitions = useTransition(true, {
      from: { ...styles.containerBackgroundFrom },
      enter: { ...styles.containerBackground },
      config: config.molasses,
    });

    if (!first) {
      return (
        <div style={{ ...styles.container, ...styles.containerBackground }}>
          <Buttons buttons={buttons} selectedIndex={selectedIndex} handleClick={handleClick} />
        </div>
      );
    }

    return transitions((style) => (
      <animated.div style={{ ...style, ...styles.container }}>
        <Buttons buttons={buttons} selectedIndex={selectedIndex} handleClick={handleClick} />
      </animated.div>
    ));
  };

  const TransitionOpacityNavbar = () => {
    return transitions((style) => (
      <animated.div style={{ ...style, ...styles.navbar }} id="navbar">
        <Container />
      </animated.div>
    ));
  };

  const Navbar = () => {
    return (
      <div className="navbar" style={{ ...styles.navbar }} id="navbar">
        <Container />
      </div>
    );
  };

  if (!show) {
    return <></>;
  }

  return <div>{first ? <TransitionOpacityNavbar /> : <Navbar />}</div>;
};

export default WebNavigationBar;

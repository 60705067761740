import { Style } from "../../globalTypes";
import { Theme } from "@mui/material";
import { ScreenSize } from "../../hooks/useDimensions/types";

export const useDiaryPageStyle = (theme: Theme, screenSize: ScreenSize, hide: boolean): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  return {
    container: { backgroundColor: theme.palette.background.default },
    containerHeader: {
      height: isSmall ? "100vh" : "80vh",
      width: "100%",
      overflow: "scroll",
      opacity: hide ? 0 : 1,
    },
    overlayComponentStyle: {
      height: isSmall ? "100vh" : "80vh",
    },
    imageContainerStyle: {
      height: isSmall ? "100vh" : "80vh",
    },
    articleContainer: {
      padding: isSmall ? 8 : 24,
      paddingTop: isSmall ? 16 : 136,
      paddingBottom: isSmall ? 16 : 112,
      position: "relative",
      backgroundColor: theme.palette.background.paper,
    },
    articleItem: { display: "flex", justifyContent: "center", marginBottom: isSmall ? undefined : 24 },
    card: {
      width: isSmall ? "100%" : "50%",
      marginLeft: isSmall ? 8 : undefined,
      marginRight: isSmall ? 8 : undefined,
    },
    styleImg: {
      height: isSmall ? "450px" : "550px",
    },
    modalBcg: {
      position: "absolute",
      width: "100vw",
      height: `10000px`,
      //backdropFilter: "blur(2px)",
      backgroundColor: "rgba(29, 27, 27, 0.4)",
      zIndex: "1000",
    },
  };
};

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useMemo } from "react";
import { Image } from "../../../schemas";
import FocusedImage from "../focusedImage/FocusedImage";
import "./style.css";

interface ISlider {
  images: Image[];
  loading?: boolean;
  style?: React.CSSProperties;
  onLoadImage?: () => void;
}

const Carousel = (props: ISlider) => {
  const { images, loading, onLoadImage } = props;

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    autoplaySpeed: 4000,
    customPaging: function () {
      return <div className="dot" />;
    },
    dotsClass: "slick-dots slick-thumb",
  };

  const renderSlides = useMemo(() => {
    return images.map((image, index) => (
      <div key={index}>
        <FocusedImage
          key={image.id}
          src={image.image}
          alt={image.image_alt}
          focus={false}
          style={{
            display: loading ? "none" : "block",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            outline: "none",
          }}
          onLoadImage={onLoadImage}
        />
      </div>
    ));
  }, [images]);

  return <Slider {...settings}>{renderSlides}</Slider>;
};
export default Carousel;

import React from "react";
import { Image } from "../../../schemas";
import "./style.css";
import FocusedImage from "../focusedImage/FocusedImage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "../carousel/Carousel";

interface ICustomImages {
  images: Image[];
  loading?: boolean;
  style?: React.CSSProperties;
  onLoadImage?: () => void;
}

const CustomImages = (props: ICustomImages) => {
  const { images, loading, onLoadImage } = props;

  if (!images) {
    return <></>;
  }

  return (
    <>
      {loading ? (
        <FocusedImage
          src={images[0].image}
          alt={images[0].image_alt}
          style={{ display: "none" }}
          onLoadImage={onLoadImage}
        />
      ) : (
        <Carousel
          images={images}
          onLoadImage={onLoadImage}
          style={{ display: loading ? "none" : "block" }}
          loading={loading}
        />
      )}
    </>
  );
};

export default CustomImages;

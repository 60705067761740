import useDiaryPage from "./useDiaryPage";
import { Paths } from "../../navigation/types";
import Footer from "../../components/molecules/footer/Footer";
import AnimatedPage from "../../components/templates/AnimatedPage";
import React, { useMemo } from "react";
import ArticleCard from "../../components/atoms/articleCard/ArticleCard";
import { Grid } from "@mui/material";
import Logo from "../../components/atoms/Logo/Logo";
import { LoadingSkeleton } from "../../components/atoms/loadingSkeleton/LoadingSkeleton";
import ImageHeader from "../../components/atoms/image/Image";

const DiaryPage = () => {
  const { articles, overlayTxt, overlay, img, loading, styles, t, onLoadImage } = useDiaryPage();

  const Header = useMemo(() => {
    return (
      <div style={styles.containerHeader}>
        <Logo show={true} containerStyle={{ position: "absolute" }} />
        {loading && <LoadingSkeleton width="100%" height="100%" />}
        <ImageHeader
          src={img}
          y={0.6}
          text={overlayTxt.toUpperCase()}
          alt={t("label.altExImage")}
          onLoadImage={onLoadImage}
          overlayComponentStyle={styles.overlayComponentStyle}
          imageContainerStyle={styles.imageContainerStyle}
          textStyle={styles.textStyle}
        />
      </div>
    );
  }, [loading, styles]);

  const MainComponent = useMemo(() => {
    return (
      <Grid id="diary-container" container columns={{ xs: 1 }} style={styles.articleContainer}>
        {articles &&
          articles.map((article, index) => (
            <Grid item p={2} xs={1} key={index} style={styles.articleItem}>
              <ArticleCard article={article} style={styles.card} styleImg={styles.styleImg} variantTitle={"h6"} />
            </Grid>
          ))}
      </Grid>
    );
  }, [articles, t, styles]);

  return (
    <AnimatedPage key={Paths.Diary} style={styles.container}>
      <>
        {overlay && <div style={styles.modalBcg} />}
        {Header}
        {MainComponent}
        <Footer />
      </>
    </AnimatedPage>
  );
};

export default DiaryPage;

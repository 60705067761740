import React from "react";
import { useDimensions } from "../../../hooks/useDimensions/useDimensions";
import { useImageStyle } from "./style";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CustomTypography from "../customTypography/customTypography";
import FocusedImage from "../focusedImage/FocusedImage";

const initValue = 0.000000001;

export interface Image {
  src: string;
  alt: string;
  x?: number;
  y?: number;
  onLoadImage?: () => void;
  overlayComponentStyle?: React.CSSProperties;
  imageContainerStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  text?: string;
}

const ImageHeader = (props: Image) => {
  const {
    alt,
    src,
    x = initValue,
    y = initValue,
    overlayComponentStyle,
    imageContainerStyle,
    textStyle,
    text,
    onLoadImage,
  } = props;
  const { screenSize } = useDimensions();
  const styles = useImageStyle(screenSize);

  return (
    <>
      <div style={{ ...styles.background, ...overlayComponentStyle }}>
        <>
          <div style={{ ...styles.container, flex: 1.3 }}>
            {text && <CustomTypography style={{ ...styles.text, ...textStyle }}>{text}</CustomTypography>}
          </div>
          <div style={styles.container}>
            <KeyboardArrowDownIcon style={styles.arrowDown} />
          </div>
        </>
      </div>
      <div style={{ ...styles.imgContainer, ...imageContainerStyle }}>
        <div style={{ width: "100%", height: "100%" }}>
          <FocusedImage alt={alt} src={src} x={x} y={y} onLoadImage={onLoadImage} />
        </div>
      </div>
    </>
  );
};

export default ImageHeader;

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CustomTypography from "../../atoms/customTypography/customTypography";
import AppBarCustom from "../../atoms/appBar/AppBar";
import CustomDrawerHeader from "../../atoms/drawerHeader/DrawerHeader";
import Main from "./components/Main";
import { IPersistentDrawerLeft } from "./types";
import { useDrawer } from "./useDrawer";
import { useDrawerStyle } from "./styles";
import { TransformWrapper, TransformComponent } from "@pronestor/react-zoom-pan-pinch";
import { useMemo } from "react";
import { isMobileSafari } from "react-device-detect";

const PersistentDrawerLeft = (props: IPersistentDrawerLeft) => {
  const { children, images, onMiniatureClick } = props;
  const { drawerWidth, open, handleDrawerOpen, handleDrawerClose, onMainClick } = useDrawer();
  const styles = useDrawerStyle();

  const Component = useMemo(() => {
    return (
      <>
        <TransformWrapper
          initialScale={1}
          minScale={0}
          maxScale={4}
          wheel={{ wheelDisabled: true, step: 0.2 }}
          panning={{ disabled: isMobileSafari ? true : false }}
        >
          <Main onClick={onMainClick}>
            <TransformComponent>{children}</TransformComponent>
          </Main>
        </TransformWrapper>
      </>
    );
  }, []);

  return (
    <Box component="div" sx={{ display: "flex" }}>
      <CssBaseline />
      {!open && <AppBarCustom handleDrawerOpen={handleDrawerOpen} />}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          opacity: open ? 1 : 0,
        }}
        variant="permanent"
        anchor="left"
      >
        <CustomDrawerHeader handleDrawerClose={handleDrawerClose} />
        <List>
          {images.map((image, index) => (
            <ListItem key={index} style={styles.listItem}>
              <ListItemButton style={styles.listItemButton}>
                <div style={styles.imageContainer}>
                  <img
                    src={image.img}
                    alt={"image_alt_" + index}
                    className={"image_drawer"}
                    onClick={() => {
                      onMiniatureClick("image_id_" + index);
                    }}
                  />
                </div>
              </ListItemButton>
              <CustomTypography style={styles.typo} fontWeight="light">
                {index + 1}
              </CustomTypography>
            </ListItem>
          ))}
        </List>
      </Drawer>
      {Component}
    </Box>
  );
};

export default PersistentDrawerLeft;

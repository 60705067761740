import { SvgIcon } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import CustomTypography from "../../../../../atoms/customTypography/customTypography";
import React from "react";
import { useV1NavigationBarStyle } from "./v1.styles";
import { useTranslation } from "react-i18next";
import { INavigationBarVariant } from "../types";

const V1NavigationBar = (props: INavigationBarVariant) => {
  const { button, selected, onButtonClick } = props;
  const styles = useV1NavigationBarStyle();
  const { t } = useTranslation();

  const easing = {
    enter: "cubic-bezier(0, 1.5, .8, 1)",
    exit: "linear",
  };

  return (
    <div
      onClick={() => {
        onButtonClick(button, selected);
      }}
      style={styles.container}
    >
      <>
        {selected && (
          <div style={styles.iconContainerNoChecked}>
            <SvgIcon style={selected ? styles.iconChecked : styles.iconNoChecked} inheritViewBox>
              <path d={button.icon} />
            </SvgIcon>
          </div>
        )}
        <Collapse
          orientation="horizontal"
          key={button.name.toString()}
          in={!selected}
          style={!selected ? styles.isCollapse : undefined}
          easing={easing}
        >
          <div style={styles.txtContainer}>
            <CustomTypography variant="body2" style={styles.txt}>
              {t(`label.${button.t}`)}
            </CustomTypography>
          </div>
        </Collapse>
      </>
    </div>
  );
};

export default V1NavigationBar;

import { Theme } from "@mui/material";
import { Style } from "../../globalTypes";

export const useHomePageStyles = (theme: Theme, isSmall: boolean, showHeader?: boolean): Style => {
  return {
    container: { backgroundColor: theme.palette.background.default },
    containerHeader: {
      height: isSmall ? "82vh" : "80vh",
      width: "100%",
      overflow: "scroll",
      opacity: showHeader ? 1 : 0,
    },
    recentText: {
      fontSize: isSmall ? "2.6em" : "4.2em",
      color: theme.palette.primary.light,
    },
    contentContainer: {
      position: "relative",
      padding: isSmall ? 32 : 80,
      paddingTop: isSmall ? 32 : 60,
      paddingBottom: isSmall ? 0 : 80,
      backgroundColor: theme.palette.background.paper,
      zIndex: 10,
    },
    gridContainer: {
      paddingBottom: 32,
      paddingTop: 32,
      zIndex: 1000,
    },
    modalBcg: {
      position: "absolute",
      width: "100vw",
      height: `10000px`,
      //backdropFilter: "blur(2px)",
      backgroundColor: "rgba(29, 27, 27, 0.4)",
      zIndex: "1000",
    },
  };
};

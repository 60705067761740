import { useGlobal } from "../../state/global/useGlobal";
import { useTheme } from "@mui/material/styles";
import { useWorksPageStyle } from "./styles";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { useHideHeader } from "../../hooks/useUtils/useUtils";

export const useWorksPage = () => {
  const { data, modal } = useGlobal();
  const { works } = data;
  const theme = useTheme();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { hide } = useHideHeader();
  const styles = useWorksPageStyle(theme, isSmall, hide);

  const onLoadImage = useCallback(() => {
    setLoading(false);
  }, []);

  const overlay = useMemo(() => {
    return modal.open;
  }, [modal]);

  return { styles, works, loading, overlay, t, onLoadImage };
};

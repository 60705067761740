import { Style } from "../../globalTypes";
import { ScreenSize } from "../../hooks/useDimensions/types";

export const useErrorPageStyle = (screenSize: ScreenSize): Style => {
  return {
    container: {
      height: "100vh",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    logoContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: 24,
    },
    logo: {
      height: screenSize === "sm" || screenSize === "xs" ? "88px" : "106px",
    },
  };
};

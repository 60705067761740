import React from "react";
import { useTranslation } from "react-i18next";
import NavigationButton from "../../../../../atoms/navigationButton/NavigationButton";
import { INavigationBarVariant } from "../types";

const V2NavigationBar = (props: INavigationBarVariant) => {
  const { button, selected, index, onButtonClick } = props;
  const { t } = useTranslation();

  return (
    <NavigationButton
      index={index}
      title={t(`label.${button.t}`)}
      selected={selected}
      onClick={() => {
        onButtonClick(button, selected);
      }}
    />
  );
};

export default V2NavigationBar;

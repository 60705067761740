import { Style } from "../../../../../../globalTypes";

export const useV1NavigationBarStyle = (): Style => {
  return {
    container: { display: "flex", margin: 4 },
    iconContainerChecked: {
      width: 14,
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      opacity: 1,
    },
    iconContainerNoChecked: {
      display: "flex",
      alignItems: "center",
      opacity: 1,
      marginLeft: 4,
      marginRight: 4,
    },
    iconChecked: {
      fill: "none",
      strokeWidth: 1,
      stroke: "rgb(244, 240, 240)",
    },
    iconNoChecked: {
      fill: "none",
      strokeWidth: 1,
      stroke: "rgb(244, 240, 240)",
    },
    isCollapse: { marginLeft: 2 },
    txtContainer: {
      cursor: "pointer",
      display: "flex",
      alignItems: "flex-end",
      height: "inherit",
      opacity: 0.5,
    },
    txt: {
      marginLeft: 4,
      display: "flex",
      alignItems: "flex-end",
      color: "rgb(244, 240, 240)",
    },
  };
};

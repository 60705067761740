import React, { useCallback } from "react";
import CustomTypography from "../../../../components/atoms/customTypography/customTypography";
import useTimelineStep from "./useTimelineStep";
import { ITimelineStepProps } from "./types";
import { createMarkup } from "../../../../hooks/useUtils/useUtils";
import { Exhibition } from "../../types";

const TimelineStep = (props: ITimelineStepProps) => {
  const { language, index, timelineRef, exhibitions, circleRef, show, year, styles } = useTimelineStep(props);

  const createContent = useCallback(
    (e: Exhibition) => {
      if (!language) {
        return createMarkup("");
      }
      return createMarkup(e.content[`content_${language}`]);
    },
    [language],
  );

  const createTitle = useCallback(
    (e: Exhibition) => {
      if (!language) {
        return "";
      }
      return e.title[`title_${language}`];
    },
    [language],
  );

  return (
    <>
      {year && (
        <>
          <div id={"timeline" + index} ref={timelineRef} className="timeline" />
          <div className="circleWrapper">
            <div id={"circle" + index} ref={circleRef} className="circle" />
            <div className="topic">
              <CustomTypography fontWeight={show ? "bold" : undefined} style={styles.year}>
                {year}
              </CustomTypography>
            </div>
            <>
              {show && (
                <div className="timelineStep" style={styles.exhDetail}>
                  {exhibitions.map((e, index) => {
                    return (
                      <div key={createTitle(e)} style={index > 0 ? styles.exhContainer : undefined}>
                        <CustomTypography fontWeight="bold" style={styles.title}>
                          {createTitle(e)}
                        </CustomTypography>
                        <CustomTypography fontWeight={"light"} dangerouslySetInnerHTML={createContent(e)} />
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          </div>
        </>
      )}
    </>
  );
};

export default TimelineStep;

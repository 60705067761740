import { useGlobal } from "../../state/global/useGlobal";
import { useNavigation } from "../../navigation/useNavigation";
import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Variant } from "@mui/material/styles/createTypography";
import { useArticlePageStyle } from "./styles";
import _ from "lodash";
import { ArticleSchema } from "../../schemas";
import { Article } from "../diaryPage/types";
import moment from "moment";
import { useTheme } from "@mui/material/styles";
import { useGetPath } from "../../hooks/useUtils/useUtils";

export const useArticlePage = (id?: string) => {
  const { setModal, data, language } = useGlobal();
  const { articles } = data;
  const { navigate } = useNavigation();
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const theme = useTheme();
  const styles = useArticlePageStyle(screenSize, theme);
  const [loading, setLoading] = useState(true);
  const path = useGetPath();
  const basePath = path ? path.split(":")[0] : "";

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const articleFromReducer = useMemo((): ArticleSchema | undefined => {
    if (!id) {
      return;
    }
    return _.find(articles, { id: parseInt(id) });
  }, [articles, id]);

  const getPropertyByLanguage = useCallback(
    (property: string) => {
      if (!articleFromReducer) {
        return "";
      }
      const prop = property + language;
      return articleFromReducer[prop];
    },
    [articleFromReducer, language],
  );

  const articleData = useMemo((): string => {
    if (!language) {
      return "";
    }
    moment.locale(language);
    return moment(articleFromReducer?.publication_at).locale("de").format("LL");
  }, [articleFromReducer, language]);

  const article = useMemo((): Article | undefined => {
    if (!articleFromReducer) {
      return;
    }
    return {
      id: articleFromReducer.id,
      title: getPropertyByLanguage("title_"),
      place: articleFromReducer.place,
      data: articleData,
      content: getPropertyByLanguage("content_"),
      images: articleFromReducer.images,
      imageCover: articleFromReducer.image_thumbnail,
      video: articleFromReducer.video,
      x_thumbnail_position: articleFromReducer.x_thumbnail_position,
      y_thumbnail_position: articleFromReducer.y_thumbnail_position,
    };
  }, [articleFromReducer, language]);

  const setIsLoaded = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    return () => {
      setModal({ modal: { open: false } });
    };
  }, [setModal]);

  const onModalClose = useCallback(() => {
    setModal({ modal: { open: false } });
    navigate(basePath);
  }, [navigate, setModal]);

  const variantTitle: Variant = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs" ? "h4" : "h2";
  }, [screenSize]);

  const variantPlace: Variant = useMemo(() => {
    return screenSize === "sm" || screenSize === "xs" ? "body2" : "body1";
  }, [screenSize]);

  return { article, loading, isSmall, styles, variantTitle, variantPlace, onModalClose, setIsLoaded };
};

import { useCallback, useEffect, useMemo, useState } from "react";
import { getNavbarButtons, NavButton } from "../../../../config/config";
import { useNavigationBarStyle } from "./styles";
import { useLocation } from "react-router-dom";
import { Paths } from "../../../../navigation/types";
import { useGlobal } from "../../../../state/global/useGlobal";
import { useNavigation } from "../../../../navigation/useNavigation";
import { useTransition } from "react-spring";
import { useCountDown, useGetPath, useHideShowOnScroll, useIsFirstRender } from "../../../../hooks/useUtils/useUtils";
import { isMobileSafari } from "react-device-detect";
import { useDimensions } from "../../../../hooks/useDimensions/useDimensions";

const useShowNavigationBar = () => {
  const { isDataLoaded, modal } = useGlobal();
  const [show, setShow] = useState<boolean>(true);

  useEffect(() => {
    if (isDataLoaded) {
      if (modal.open) {
        setShow(false);
      } else if (!show) {
        setShow(true);
      }
    }
  }, [isDataLoaded, modal, show]);
  return { show };
};

const getIdByPath = (path?: string) => {
  if (!path) {
    return "";
  }
  switch (path) {
    case Paths.Home:
      return "home-container";
    case Paths.Works:
      return isMobileSafari ? "scroll-image-list" : "infinite-scroll-loop-inner";
    case Paths.Exhibitions:
      return "exhibitions-container";
    case Paths.About:
      return "about-me-container";
    case Paths.Diary:
      return "diary-container";
    default:
      return "";
  }
};

const getEventTypeByPath = (path?: string) => {
  if (!path) {
    return "";
  }
  switch (path) {
    case Paths.Home:
    case Paths.Exhibitions:
    case Paths.About:
    case Paths.Diary:
      return isMobileSafari ? "touchmove" : "wheel";
    default:
      return isMobileSafari ? "touchmove" : "scroll";
  }
};

export const useNavigationBar = () => {
  const buttons = getNavbarButtons();
  const location = useLocation();
  const { navigate } = useNavigation();
  const { show } = useShowNavigationBar();
  const isFirst = useIsFirstRender();
  const path = useGetPath();
  const { isVisible } = useHideShowOnScroll(getIdByPath(path), 1500, getEventTypeByPath(path));
  const { first } = isFirst;
  const [counterStopScroll, start, , reset] = useCountDown(2, 1000);
  const { screenSize } = useDimensions();
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const styles = useNavigationBarStyle(isSmall);

  const resetCounter = useCallback(() => {
    reset();
  }, []);

  useEffect(() => {
    if (location.pathname !== "/archive") {
      return;
    }
    start();
    const el = document.getElementById("infinite-scroll-loop");
    el && el.addEventListener(isMobileSafari ? "touchmove" : "wheel", resetCounter);

    return () => {
      el && el.removeEventListener(isMobileSafari ? "touchmove" : "wheel", resetCounter);
    };
  }, [isVisible]);

  const showNavigationBar = useMemo(() => {
    if (location.pathname !== "/archive") {
      return isVisible;
    }
    return isVisible && counterStopScroll === 0;
  }, [isVisible, counterStopScroll]);

  //* THIS EFFECT DISABLE SCROLL ON NAVIGATION BAR*//
  useEffect(() => {
    if (!showNavigationBar) {
      return;
    }
    const navbar = document.getElementById("navbar");
    navbar &&
      navbar.addEventListener(isMobileSafari ? "touchmove" : "wheel", (e) => {
        e.preventDefault();
      });
    return () => {
      navbar &&
        navbar.removeEventListener(isMobileSafari ? "touchmove" : "wheel", (e) => {
          e.preventDefault();
        });
    };
  }, [showNavigationBar]);

  const selectedIndex = useMemo(() => {
    if (location.pathname.includes(Paths.Home)) {
      return 0;
    }
    if (location.pathname.includes(Paths.Works)) {
      return 1;
    }
    if (location.pathname.includes(Paths.Diary)) {
      return 3;
    }
    switch (location.pathname) {
      case Paths.Exhibitions:
        return 2;
      case Paths.About:
        return 4;
      case Paths.Contact:
        return 5;
      default:
        return null;
    }
  }, [location]);

  const handleClick = useCallback(
    (button: NavButton, selected?: boolean) => {
      if (!selected) {
        navigate(button.path);
      }
    },
    [navigate],
  );

  const transitions = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });

  return {
    buttons,
    first,
    showNavigationBar,
    path,
    selectedIndex,
    show: true,
    styles,
    transitions,
    handleClick,
  };
};

import { Style } from "../../globalTypes";
import { ScreenSize } from "../../hooks/useDimensions/types";
import { Theme } from "@mui/material";

export const useProjectPageStyle = (screenSize: ScreenSize, theme: Theme): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  return {
    container: { padding: isSmall ? 0 : 8 },
    headerContainer: { paddingTop: isSmall ? "3rem" : "5rem", paddingBottom: isSmall ? "2em" : "3.5rem" },
    placeContainer: {
      display: "flex",
      marginLeft: isSmall ? "-4px" : "4px",
      flexDirection: isSmall ? "column" : undefined,
      alignItems: isSmall ? undefined : "flex-start",
    },
    iconContainerSmall: { width: "90%", display: "flex", marginTop: 4 },
    headerText: {
      display: "flex",
      alignItems: isSmall ? "flex-end" : undefined,
    },
    headerTextSmall: {
      marginTop: 3,
    },
    icon: {
      width: isSmall ? "0.6em" : "0.8em",
      marginLeft: 4,
      marginRight: 4,
      marginBottom: isSmall ? 0 : 2,
      color: theme.palette.primary.light,
    },
    title: {
      width: "98%",
      marginTop: 12,
    },
    imgContainer: { padding: 16, borderRadius: 16, backgroundColor: "whitesmoke" },
    image: { borderRadius: 8 },
    description: {
      padding: isSmall ? 0 : 16,
      paddingTop: isSmall ? "3rem" : "5rem",
      paddingBottom: isSmall ? "2.5rem" : "4rem",
      color: theme.palette.primary.light,
    },
    videoContainer: {
      width: "100%",
      height: "40vh",
      display: "flex",
      justifyContent: "center",
      marginTop: isSmall ? 0 : -16,
      marginBottom: isSmall ? "2.5rem" : "4rem",
    },
    video: { width: isSmall ? "100%" : "50%", height: "100%" },
  };
};

import { useDimensions } from "../../hooks/useDimensions/useDimensions";
import { useLayoutEffect, useMemo, useState } from "react";
import { scroller } from "react-scroll";
import { usePrivateWorksLoader } from "./hooks/usePrivateWorksLoader";
import { ImageCatalog } from "../../schemas";
import _ from "lodash";

const settings = {
  infinite: false,
  vertical: true,
  verticalSwiping: false,
  swipeToSlide: true,
  slidesToScroll: 1,
  autoplay: false,
  arrows: false,
};

export const usePrivateWorks = () => {
  const { loading, error, data } = usePrivateWorksLoader();
  const { window: windowPage } = useDimensions();
  const [offset, setOffset] = useState(0);

  const images = useMemo((): ImageCatalog[] => {
    if (loading) {
      return [];
    }
    return _.sortBy(data, (image) => image.sort_order);
  }, [loading, data]);

  useLayoutEffect(() => {
    const header = document.getElementsByTagName("header")[0];
    if (!header) {
      return;
    }
    setOffset(header.offsetHeight - 24);
  }, []);

  const onMiniatureClick = (id: string) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -offset,
    });
  };

  return {
    loading,
    error,
    images,
    windowPage,
    onMiniatureClick,
    settings,
  };
};

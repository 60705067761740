import * as React from "react";
import Box from "@mui/material/Box";
import { useMobileNavigatorStyle } from "./styles";
import { useTheme } from "@mui/material/styles";
import { NavButton } from "../../../../../config/config";
import { Divider } from "@mui/material";
import CustomTypography from "../../../../atoms/customTypography/customTypography";
import { useCallback } from "react";

interface MobileNavigatorProps {
  buttons: NavButton[];
  handleClickRoute: (button: NavButton, selected?: boolean) => void;
  selectedIndex: number | null;
}

const MobileNavigator = (props: MobileNavigatorProps) => {
  const { buttons, handleClickRoute, selectedIndex } = props;
  const theme = useTheme();
  const styles = useMobileNavigatorStyle(theme);
  const getFontWeight = useCallback(
    (index: number) => {
      return index === selectedIndex ? "bold" : undefined;
    },
    [selectedIndex],
  );

  return (
    <Box component="div" style={styles.container}>
      <>
        {buttons.map((button, index) => (
          <React.Fragment key={button.name.toString()}>
            <Box component="div" p={2}>
              <CustomTypography fontWeight={getFontWeight(index)} onClick={() => handleClickRoute(button)}>
                {button.name}
              </CustomTypography>
            </Box>
            {index !== buttons.length - 1 && <Divider style={styles.divider} variant="inset" />}
          </React.Fragment>
        ))}
      </>
    </Box>
  );
};

export default MobileNavigator;

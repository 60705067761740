import { Theme } from "@mui/material";
import { Style } from "../../../../globalTypes";
import { ScreenSize } from "../../../../hooks/useDimensions/types";

export const useTimelineStepStyle = (theme: Theme, screenSize: ScreenSize): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  return {
    year: {
      color: theme.palette.primary.light,
    },
    exhDetail: {
      padding: 24,
      paddingTop: 0,
      paddingBottom: 0,
      marginLeft: 8,
      marginRight: 8,
      color: theme.palette.primary.light,
    },
    exhContainer: {
      marginTop: 8,
    },
    title: {
      paddingBottom: 4,
    },
    icon: {
      width: isSmall ? "0.5em" : "0.7em",
      marginLeft: isSmall ? 4 : 8,
      paddingTop: 4,
      marginBottom: -4,
    },
  };
};

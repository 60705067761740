import { Theme } from "@mui/material";
import { ScreenSize } from "../../../hooks/useDimensions/types";
import { Style } from "../../../globalTypes";

export const useFooterStyle = (theme: Theme, screenSize: ScreenSize): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  return {
    container: {
      minHeight: "5vh",
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      position: "relative",
      zIndex: 999,
    },
    divider: {
      marginLeft: isSmall ? 20 : 40,
      marginRight: isSmall ? 20 : 40,
      color: theme.palette.primary.light,
    },
    dividerNoMargin: {
      color: theme.palette.primary.light,
    },
    dataContainer: {
      minHeight: isSmall ? "100px" : "160px",
      width: "100%",
      display: "flex",
      flexDirection: isSmall ? "column" : undefined,
      paddingLeft: isSmall ? 20 : 80,
      paddingRight: isSmall ? 20 : 80,
    },
    dataContainerNoPadding: {
      minHeight: isSmall ? "100px" : "160px",
      width: "100%",
      display: "flex",
      flexDirection: isSmall ? "column" : undefined,
    },
    socialContainer: {
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: isSmall ? "space-between" : "flex-end",
    },
    languageContainer: { display: "flex", alignItems: "center", marginLeft: 32 },
    leftContainer: {
      display: "flex",
      flex: isSmall ? undefined : 1,
      alignItems: "center",
    },
    icon: {
      height: "40px",
      width: "40px",
      color: theme.palette.primary.light,
      cursor: "pointer",
    },
    copyContainer: {
      display: "flex",
      justifyContent: "center",
      paddingTop: isSmall ? 32 : 68,
      paddingBottom: isSmall ? 32 : 68,
      paddingLeft: isSmall ? 20 : 80,
      paddingRight: isSmall ? 20 : 80,
      textAlign: "center",
      backgroundColor: theme.palette.background.paper,
    },
    copyTxt: { color: theme.palette.primary.light, fontSize: isSmall ? "0.8em" : undefined },
    logoContainer: {
      justifyContent: "flex-end",
      paddingTop: 16,
      paddingBottom: 16,
    },
    logo: {
      height: isSmall ? "48px" : "66px",
    },
    mailContainer: {
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    containerSocial: {
      display: isSmall ? undefined : "flex",
      flexDirection: isSmall ? undefined : "row-reverse",
    },
  };
};

import { Style } from "../../../globalTypes";
import { Theme } from "@mui/material";
import { ScreenSize } from "../../../hooks/useDimensions/types";
import { useMemo } from "react";

export const useWorkCardStyle = (theme: Theme, screenSize: ScreenSize): Style => {
  const isSmall = screenSize === "sm" || screenSize === "xs";
  const { matches: isLandscape } = window.matchMedia("(orientation: landscape)");

  const height = useMemo(() => {
    if (isLandscape && isSmall) {
      return "240px";
    }
    return isSmall ? "450px" : "400px";
  }, [isSmall, isSmall]);

  return {
    card: {
      width: isSmall ? "100%" : "30vw",
      borderRadius: 0,
      boxShadow: "none",
    },
    imgContainer: {
      width: "100%",
      height: height,
      cursor: "pointer",
    },
    background: {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      position: "absolute",
      backgroundColor: "#FFFFFF",
      opacity: 0.25,
    },
    cardContent: {
      paddingTop: 8,
      color: theme.palette.primary.light,
    },
    date: {
      fontSize: "0.75em",
      color: theme.palette.primary.light,
    },
    readContainer: {
      display: "flex",
      backgroundColor: "rgba(29, 27, 27, 0.4)",
      backdropFilter: "blur(4px)",
      padding: 8,
      paddingLeft: 16,
      paddingRight: 8,
      borderTopLeftRadius: 8,
      cursor: "pointer",
    },
    read: { fontSize: "0.75em", color: theme.palette.background.default },
    icon: { fontSize: "0.9em", color: theme.palette.background.default, marginLeft: 4, paddingTop: 2 },
  };
};
